export const navigation = {
    categories: [
      {
        id: 'home',
        name: 'Home',
        route:"/"
    
      },
      {
        id: 'products',
        name: 'All Product',
        route:"/product"
      },
      {
        id: 'about',
        name: 'About',
        route:"/about"
      },
      {
        id: 'contact',
        name: 'Contact',
        route:"/contact"
      },
    ],
    // pages: [
    //   { name: 'Company', id: '/' },
    //   { name: 'Stores', id: '/' },
    // ],
  }