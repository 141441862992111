import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { removeCartItem, updateCartItem } from "../../../Redux/Customers/Cart/Action";
import { removeItemFromLocalCart, updateLocalCartItem } from "../../../Redux/Customers/LocalCart/CartActions";

const CartItem = ({ item, showButton, setToggle, toggle }) => {
  const [cartToggle, setCartToggle] = useState(false)
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");

  const isAuthenticated = !!jwt;

  const handleRemoveItemFromCart = () => {
    if (isAuthenticated) {
      const data = { cartItemId: item?._id, jwt };
      dispatch(removeCartItem(data));
      setToggle(!toggle);
    } else {
      dispatch(removeItemFromLocalCart(item?.productId));
      setToggle(!toggle);
    }
  };
  
  // const handleRemoveItemFromCart = () => {
  //   const data = { cartItemId: item?._id, jwt };
  //   dispatch(removeCartItem(data));
  //   setToggle(!toggle)
  // };
  // const handleUpdateCartItem = (num) => {
  //   const data = { data: { quantity: item.quantity + num }, cartItemId: item?._id, jwt }
  //   dispatch(updateCartItem(data))
  // }
  const handleUpdateCartItem = (num) => {
    if (isAuthenticated) {
      const data = { data: { quantity: item.quantity + num }, cartItemId: item?._id, jwt };
      dispatch(updateCartItem(data));
    } else {
      // Handle local cart update for non-authenticated users
      const updatedQuantity = item.quantity + num;
      if (updatedQuantity > 0) {
        dispatch(updateLocalCartItem(item?.productId, updatedQuantity));
      }
    }
  };
  

  return (
    <div className="p-3 shadow-md border rounded-md">
      <div className="flex items-center">
        <div className="w-[4rem] h-[4rem] lg:w-[7rem] lg:h-[7rem]">
          <img
            className="w-full h-full object-cover object-top"
            src={item?.product?.imageUrl?.length > 0 ? item?.product?.imageUrl[0] : "Image"}
            loading="lazy"
            alt={item?.product?.title || "Product Image"}
          />
        </div>
        <div className="ml-3 space-y-1">
          <p className="font-semibold text-sm lg:text-base">{item?.product?.title}</p>
          <p className="opacity-70 text-xs lg:text-sm">Size: {item?.size}, White</p>
          <p className="opacity-70 mt-1 text-xs lg:text-sm">Seller: {item?.product?.brand}</p>
          <div className="flex space-x-2 items-center pt-2">
            <p className="opacity-50 line-through text-sm lg:text-base">₹{Number(item?.product?.price)?.toFixed(2)}</p>
            <p className="font-semibold text-sm lg:text-base">₹{Number(item?.product?.discountedPrice).toFixed(2)}</p>
            <p className="text-green-600 font-semibold text-xs lg:text-sm">
              {Number(item?.product?.discountPersent)?.toFixed(2)}% off
            </p>
          </div>
        </div>
      </div>
      {showButton && (
        <div className="lg:flex items-center lg:space-x-6 pt-3">
          <div className="flex items-center space-x-1">
            <IconButton
              onClick={() => handleUpdateCartItem(-1)}
              disabled={item?.quantity <= 1}
              color="primary"
              aria-label="decrease quantity"
            >
              <RemoveCircleOutlineIcon fontSize="small" />
            </IconButton>
            <span className="py-1 px-5 border rounded-sm text-sm">{item?.quantity}</span>
            <IconButton
              onClick={() => handleUpdateCartItem(1)}
              color="primary"
              aria-label="increase quantity"
            >
              <AddCircleOutlineIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="flex text-xs lg:text-sm mt-4 lg:mt-0">
            <Button onClick={handleRemoveItemFromCart} variant="text">
              Remove
            </Button>
          </div>
        </div>
      )}
    </div>

  );
};

export default CartItem;
