import React from 'react';
import { IconButton, Tooltip, Typography, Box } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppLink = () => {
  // Replace with your WhatsApp number and message
  const appDetailsString = localStorage.getItem('appDetails');
  const appDetails = JSON.parse(appDetailsString);

  const whatsappUrl = `https://wa.me/${appDetails?.whatssapp}?text=Hello!`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <Tooltip title="Click to send a message on WhatsApp!" arrow>
        <IconButton
          component="a"
          href={whatsappUrl}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: 'white', 
            backgroundColor: '#25D366', 
            '&:hover': {
              backgroundColor: '#1DA851',
            },
            fontSize: '2rem', 
            padding: '20px',
          }}
        >
          <WhatsAppIcon sx={{ fontSize: '2.5rem', backgroundColor:'white' }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default WhatsAppLink;
