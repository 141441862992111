import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { dressPage1 } from "../../../Data/dress/page1";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  findProducts,
  findProductsAdmin
} from "../../../Redux/Customers/Product/Action";
import { Category_level, SearchParmas } from "../../../utils/utils";
import { getCategory, getCategoryById } from "../../../Redux/Category/Action";
import BackdropComponent from "../../../customer/Components/BackDrop/Backdrop";
import SearchableDropdown from "../SearchableDropdown";
import { useConfirm } from "material-ui-confirm";
import { useDebounceSearch } from "../../../utils/useDebounce";
import api, { API_BASE_URL } from "../../../config/api";
import axios from 'axios';

const ProductsTable = () => {
  const location = useLocation();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customersProduct } = useSelector((store) => store);
  const [file, setFile] = useState(null);

  const {
    category: MainCategory,
    subcategory,
    loading: catLoading
  } = useSelector((store) => store?.adminCategory);
  const [filterValue, setFilterValue] = useState({
    availability: "",
    category: "",
    sort: "",
    searchTerm: ""
  });
  const debounceSearch = useDebounceSearch(filterValue?.searchTerm)
  useEffect(() => {
    (() => {
      let data2 = {
        level: Category_level?.TOP,
        pageNumber: 1,
        pageSize: 0,
        searchTerm: ""
      };
      dispatch(getCategory(data2));
    })();
  }, []);
  // query
  const searchParams = new URLSearchParams(location.search);
  const availability = searchParams.get("availability");
  const category = searchParams.get("category");
  const sort = searchParams.get("sort");
  const page = searchParams.get("page") || 1;
  const parent_category = searchParams.get("parent_category");

  const handlePaginationChange = (event, value) => {
    searchParams.set("page", value);
    const query = searchParams.toString();
    navigate({ search: `?${query}` });
  };

  const fetch = () => {
    const data = {
      category: filterValue?.category || "",
      colors: [],
      sizes: [],
      minPrice: 0,
      maxPrice: 100000,
      minDiscount: 0,
      sort: filterValue?.sort || "price_low",
      pageNumber: (filterValue?.searchTerm || filterValue?.parentCategory || filterValue?.category) ? 1 : page,
      pageSize: 100,
      stock: filterValue?.availability,
      searchTerm: debounceSearch,
      parent_category: filterValue?.parentCategory || ""
    };
    dispatch(findProductsAdmin(data));
    setFilterValue((prev) => {
      return {
        ...prev,
        secondLavelCategory: subcategory?.find(
          (data) => data?._id === category
        ),
      };
    });
  }
  useEffect(() => {
    // setFilterValue({ availability, category, sort });
    fetch();
  }, [filterValue?.availability, filterValue?.parentCategory, filterValue?.category, filterValue?.sort, page, customersProduct.deleteProduct, debounceSearch]);

  const handleFilterChange = (e, sectionId) => {
    console.log("data=>>>", e.target.value)
    setFilterValue((values) => ({ ...values, [sectionId]: e.target.value }));
    // searchParams.set(sectionId, e.target.value);
    // const query = searchParams.toString();
    // navigate({ search: `?${query}` });
  };

  const handleDeleteProduct = (product) => {

    confirm({
      description: <p className="font-medium">This will permanently delete <span className="font-bold">{product?.title}</span></p>,
    })
      .then(() => dispatch(deleteProduct(product?._id)))
      .catch(() => { });
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setFilterValue(prevData => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      console.error('No file selected');
      return;
    }
  
    try {
      setFile(selectedFile);
  
      // Create a FormData object and append the file
      const formData = new FormData();
      formData.append('ImportProducts', selectedFile);
  
      // Make a POST request to your API endpoint
      const response = await axios.post(`${API_BASE_URL}/api/products/ImportProducts`, formData);
  
      // Handle success response
      if (response.data.status) {
        console.log('File uploaded successfully');
        // Additional success handling code
      } else {
        console.error('File upload failed:', response.data.message);
        // Additional failure handling code
      }
    } catch (error) {
      console.error('Error uploading file:', error.message);
    } finally {
      // Reset file input
      event.target.value = null;
      fetch();
    }
  };
  


  return (
    <Box width={"100%"}>
      <BackdropComponent open={customersProduct?.loading} />
      <Card sx={{ overflow: "visible" }} className="p-3 overflow-visible">
        <div
          style={{ justifyContent: "space-between", alignItems: "center" }}
          className="flex"
        >
          <CardHeader
            title="Products"
            sx={{
              alignItems: "center",
            }}
          />
          <Button
            onClick={() => navigate("/admin/product/create")}
            variant="contained"
          >
            Add Product
          </Button>
        </div>
        <Grid container spacing={2}>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Availability
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterValue.availability}
                label="Availability"
                onChange={(e) => handleFilterChange(e, "availability")}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"in_stock"}>Instock</MenuItem>
                <MenuItem value={"out_of_stock"}>Out Of Stock</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <SearchableDropdown
                titleKey={"name"}
                labelTitle={"Category"}
                placeholder={"Select Category..."}
                options={MainCategory}
                defaultVal={
                  MainCategory?.find(
                    (data) => data?._id === filterValue?.parentCategory
                  )?.name
                }
                handleOption={(e) => {
                  dispatch(
                    getCategoryById({
                      parentCatId: e?._id,
                      level: Category_level?.SECONDARY,
                    })
                  );
                  setFilterValue((prev) => {
                    return { ...prev, parentCategory: e?._id };
                  });
                }}
              />

            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <SearchableDropdown
                titleKey={"name"}
                labelTitle={"SubCategory"}
                options={subcategory}
                loading={catLoading}
                defaultVal={
                  subcategory?.find(
                    (data) => data?._id === filterValue?.category
                  )?.name
                }
                handleOption={(e) => {
                  setFilterValue((prev) => {
                    return { ...prev, category: e?._id };
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Sort By Price
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterValue.sort}
                label="Sort By Price"
                onChange={(e) => handleFilterChange(e, "sort")}
              >
                <MenuItem value={"price_high"}>Heigh - Low</MenuItem>
                <MenuItem value={"price_low"}>Low - Heigh</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="outlined-adornment-password"
                name="searchTerm"

                value={filterValue?.searchTerm}
                onChange={handleChange}
                type={"text"}
                placeholder="Search By Title,Category,SubCategory, Artical No....."
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"

                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search Term"
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <input
              accept=".xlsx, .xls"
              id="import-make"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <label htmlFor="import-make">
              <Button
                variant="contained"
                component="span"
                sx={{
                  marginRight: '10px',
                  // borderRadius: '14px',
                  borderColor: 'red',
                }}
              >
                Import Products
              </Button>
            </label>

          </Grid>
        </Grid>
      </Card>
      <Card className="mt-2">
        <CardHeader
          title="All Products"
          sx={{
            pt: 2,
            alignItems: "center",
            "& .MuiCardHeader-action": { mt: 0.6 },
          }}
        />
        <TableContainer>
          <Table sx={{ minWidth: 800 }} aria-label="table in dashboard">
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Title</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Size Of Packet (KG)</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Price Per Packet</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Price</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Discounted Price</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Discounted %</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Quantity</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Product Size</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Artical No</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Product Color</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Parent Category</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Category</TableCell>
                <TableCell sx={{ textAlign: "center", minWidth: 500 }}>Description</TableCell>
                <TableCell sx={{ textAlign: "center" }}>ID</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customersProduct?.products?.map((item) => (
                <TableRow
                  hover
                  key={item.name}
                  sx={{ "&:last-of-type td, &:last-of-type th": { border: 0 } }}
                >
                  <TableCell>
                    {" "}
                    <Avatar alt={item.titel} src={item.imageUrl[0]} />{" "}
                  </TableCell>

                  <TableCell
                    sx={{ py: (theme) => `${theme.spacing(0.5)} !important` }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "0.875rem !important",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="caption">{item.brand}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.size}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.pricePerKG}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.price}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.discountedPrice}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.discountPersent}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.product_size}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.article_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.color}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.parentCategoryData?.name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.categoryData?.name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.description}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?._id}
                  </TableCell>

                  <TableCell sx={{ textAlign: "center" }}>
                    <Button
                      variant="text"
                      onClick={() =>
                        navigate(`/admin/product/update/${item?._id}`)
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => handleDeleteProduct(item)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card className="mt-2 border">
        {/* <Pagination
          className="py-5 border w-auto"
          size="large"
          count={10}
          color="primary"
          onChange={handlePaginationChange}
        /> */}

        <div className="mx-auto px-4 py-5 flex justify-center shadow-lg rounded-md">
          <Pagination
            className="py-5 w-auto"
            size="large"
            page={Number(page)}
            count={Math.ceil(customersProduct?.totalCount / 100)}
            color="primary"
            onChange={handlePaginationChange}
          />
        </div>
      </Card>
    </Box>
  );
};

export default ProductsTable;
