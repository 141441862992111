import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findProducts } from "../Redux/Customers/Product/Action";

import {
    Button,
    Paper,
    List,
    MenuItem,
    Menu,
    TextField,
    Typography,
    Grid,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { getDashboard } from "../Redux/Dashboard/Action";

import {
    getCategory,
    getCategoryById,
    getProductCategory,
    getSubCategory,
} from "../Redux/Category/Action";
import {
    Category_level,
    DiscountPercentage,
    MaxPrice,
    MinPrice,
    SearchParmas,
} from "../utils/utils";
import { GET_SUBCATEGORY_BY_ID_SUCCESS } from "../Redux/Category/ActionTypes";

const Navbar = () => {

    // const MainCategorys = [...] // Assuming this is your array of categories
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleMoreCategoriesClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const {
        category: MainCategory,
        subcategory,

    } = useSelector((store) => store?.adminCategory);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const searchParams = new URLSearchParams(location.search);
    const availability = searchParams.get("availability");
    const sort = searchParams.get("sort");
    const page = searchParams.get("page") || 1;
    const price_range_from = searchParams.get("price_range.from");
    const price_range_to = searchParams.get("price_range.to");
    const discount = searchParams.get("discount");

    // const [Loading,setLoading]=useState(false)
    const [productData, setProductData] = useState({
        topLavelCategory: "",
        secondLavelCategory: "",
        minPrice: 0,
        maxPrice: 10000,
        searchTerm: "",
        category: "",
        parent_category: ""
    });

    useEffect(() => {
        let data2 = {
            level: Category_level?.TOP,
            pageNumber: 1,
            pageSize: 0,
            searchTerm: ""
        };
        dispatch(getCategory(data2));
    }, []);


    useEffect(() => {
        const data = {
            category: productData?.category || "",
            colors: [],
            sizes: [],
            minPrice:
                Number(
                    MinPrice.find((data) => data.title === price_range_from)?.value
                ) || 0,
            maxPrice:
                Number(MaxPrice.find((data) => data.title === price_range_to)?.value) ||
                "",
            minDiscount: discount || "",
            sort: sort || "",
            pageNumber: page || 1,
            pageSize: 20,
            stock: availability,
            parent_category: productData?.parent_category || "",
            searchTerm: searchTerm,
        };
        dispatch(getDashboard(data))
    }, [
        productData?.parent_category,
        productData?.category,
        searchTerm
    ]);

    const handleCatSelect = (e, valueAssociate) => {
        dispatch({
            type: GET_SUBCATEGORY_BY_ID_SUCCESS,
            payload: [],
        });
        handleChange(e, valueAssociate);
        dispatch(
            getCategoryById({
                parentCatId: e?.target?.value,
                level: Category_level?.SECONDARY,
            })
        );
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    const handleChange = (e, valueAssociate) => {
        const { name, value } = e?.target;
        console.log(name, value);
        if (name === "parent_category" || name === "category") {
            return setProductData((pData) => ({
                ...pData,
                [name]: value,
                // searchTerm:name==="category"?"": valueAssociate,
                // category:name==="parent_category"?"":value
            }));
        }

        setProductData((pData) => ({ ...pData, [name]: value }));
    };

    const handleCategoryButtonClick = (categoryId) => {
        setProductData((pData) => ({
            ...pData,
            parent_category: categoryId,
        }));
    };

    const handleAllCategoriesClick = () => {
        setProductData((pData) => ({
            ...pData,
            parent_category: "",
        }));
    };

    const [IsLargeScreen, setIsLargeScreen] = useState(true);

    useEffect(() => {
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 1024); // Example breakpoint for lg screens
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            const gridHeight = document.querySelector('.fixed-grid-header')?.offsetHeight;
            const isLargeScreen = window.innerWidth >= 1024; // lg breakpoint is typically 1024px

            if (isLargeScreen && offset > gridHeight) {

                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const fixedStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.1s ease-in-out',
        background: 'linear-gradient(to right, #ffffff, #ffffff)',
        marginTop: '155px',
        padding: '0.5rem',
    };

    const relativeStyle = {
        position: 'relative',
        transition: 'all 0.1s ease-in-out',
        background: 'linear-gradient(to right, #ffffff, #ffffff)',
        padding: '0.5rem',
    };

    return (
        <Grid
            container
            spacing={0}
            alignItems="center"
            style={isFixed ? fixedStyle : fixedStyle}
            className="fixed-grid-header"
        >
            {/* <Grid item xs={10} container spacing={2}> */}
            <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                {/* <Grid item> */}
                    <Button
                        className="m-1 py-1 px-2 rounded-lg"
                        onClick={()=>{navigate('/product')}}
                        sx={{ color: 'black', fontSize: IsLargeScreen?'12px':'10px' }}
                    >
                        All
                    </Button>
                {/* </Grid> */}

                {MainCategory.slice(0, 5).map((cat) => (
                    <div item key={cat?._id}>
                    <div
                            className="relative"
                        >
                            <Button
                                className="m-1 py-1 px-2 rounded-lg"
                                onClick={() => handleCategoryButtonClick(cat?._id)}
                                sx={{ display: 'flex', flexDirection: 'row', color: 'black', fontSize: IsLargeScreen?'12px':'10px' }}
                            >
                                {cat?.name}
                            </Button>
                        </div>
                    </div>
                ))}
                {MainCategory.length > 5 && (
                    // <Grid item>
                        <Button
                            className="m-1 py-1 px-2 rounded-lg bg-white-200 text-white-700"
                            onClick={handleMoreCategoriesClick}
                            sx={{ display: 'flex', flexDirection: 'row', color: 'black', fontSize: IsLargeScreen?'12px':'10px' }}
                        >
                            More <KeyboardArrowDownIcon />
                        </Button>
                    // </Grid>
                )}
            {/* </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} style={{ textAlign: 'center' }}> */}
                <TextField
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    // className="border-2 border-gray-200 p-1 rounded-md"
                    // size="small"
                    size="small"
                    sx={{ marginLeft: 'auto', width: IsLargeScreen? '200px': '120px', backgroundColor: '#fff', borderRadius: '4px' }}
                    InputProps={{ style: { fontSize: '12px' } }}
                />
            </Grid>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {MainCategory.slice(5).map((cat) => (
                    <MenuItem
                        key={cat?._id}
                        onClick={() => {
                            handleCategoryButtonClick(cat?._id);
                            handleMenuClose();
                        }}
                    >
                        {capitalizeFirstLetter(cat?.name)}
                    </MenuItem>
                ))}
            </Menu>
        </Grid>
    );
};

export default Navbar;

