// CartActions.js
export const ADD_ITEM_TO_LOCAL_CART = 'ADD_ITEM_TO_LOCAL_CART';
export const UPDATE_LOCAL_CART_TOTALS = 'UPDATE_LOCAL_CART_TOTALS';
export const SET_LOCAL_CART = 'SET_LOCAL_CART';
export const REMOVE_ITEM_FROM_LOCAL_CART = 'REMOVE_ITEM_FROM_LOCAL_CART';
export const UPDATE_LOCAL_CART_ITEM = 'UPDATE_LOCAL_CART_ITEM';
export const CLEAR_LOCAL_CART = 'CLEAR_LOCAL_CART';


// CartActions.js
export const addItemToLocalCart = (product) => (dispatch, getState) => {
    const { Localcart } = getState();  // Ensure this matches your reducer state name
    const { cartItems } = Localcart;  // Use cartItems as per your reducer
  
    const productIndex = cartItems.findIndex(item => item.productId === product._id);
  
    let updatedcartItems = [...cartItems];
  
    if (productIndex !== -1) {
      updatedcartItems[productIndex].quantity += 1;
    } else {
      updatedcartItems.push({
        productId: product._id,
        discountedPrice: product.discountedPrice,
        price: product.price,
        quantity: 1,
        product: product
      });
    }
  
    dispatch({ type: ADD_ITEM_TO_LOCAL_CART, payload: updatedcartItems });
    dispatch(updateLocalCartTotals());
  };
  
  export const updateLocalCartTotals = () => (dispatch, getState) => {
    const { Localcart } = getState();  // Ensure this matches your reducer state name
    const { cartItems } = Localcart;  // Use cartItems as per your reducer
  
    let totalPrice = 0;
    let totalDiscountedPrice = 0;
    let totalItem = 0;
  
    for (const item of cartItems) {
      totalPrice += item.price * item.quantity;
      totalDiscountedPrice += item.discountedPrice * item.quantity;
      totalItem += item.quantity;
    }
  
    const discount = totalPrice - totalDiscountedPrice;
  
    const localCartDetails = {
      cartItems,
      totalPrice,
      totalDiscountedPrice,
      totalItem,
      discount
    };
  
    localStorage.setItem('localCartDetails', JSON.stringify(localCartDetails));
  
    dispatch({ type: UPDATE_LOCAL_CART_TOTALS, payload: localCartDetails });
  };

  export const updateLocalCartItem = (productId, quantity) => (dispatch, getState) => {
    const { Localcart } = getState();
    const { cartItems } = Localcart;
  
    const updatedCartItems = cartItems.map(item =>
      item.productId === productId ? { ...item, quantity } : item
    );
  
    dispatch({ type: ADD_ITEM_TO_LOCAL_CART, payload: updatedCartItems });
    dispatch(updateLocalCartTotals());  // Update totals after quantity change
  };

  export const removeItemFromLocalCart = (productId) => (dispatch, getState) => {
    const { Localcart } = getState();  // Ensure this matches your reducer state name
    const { cartItems } = Localcart;  // Use cartItems as per your reducer

    const updatedCartItems = cartItems.filter(item => item.productId !== productId);
  
    dispatch({ type: REMOVE_ITEM_FROM_LOCAL_CART, payload: updatedCartItems });
    dispatch(updateLocalCartTotals());  // Optionally update totals after removal
  };
  
  export const clearLocalCart = () => (dispatch) => {
    // Clear local storage
    localStorage.removeItem('localCartDetails');
    console.log("clearLocalCart")
    // Dispatch the clear cart action
    dispatch({ type: CLEAR_LOCAL_CART });
  };
  
  export const setLocalCart = (localCart) => ({
    type: SET_LOCAL_CART,
    payload: localCart,
  });
  
