import React, { useState, useEffect } from 'react';
import { Grid, Link, Typography, IconButton } from '@mui/material';
import { Facebook, YouTube, Instagram, Pinterest, Twitter, PlayArrow} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import api, { API_BASE_URL } from "../../../config/api";

const Footer = () => {
  const [data, setdata] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/appdetails/appDetailsGet`);
        const data = response.data;
        setdata(data);
        localStorage.setItem('appDetails', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching details:', error);    }
    };

    fetchSettings();
  }, []);
  const appDetailsString = localStorage.getItem('appDetails');
  const appDetails = JSON.parse(appDetailsString);

  return (
    <Grid container className='mt-5' sx={{ py: 0, backgroundColor:data?.footerDetails?.footerBgColor, color: data?.footerDetails?.footerTextColor}}>
      {/* First Column: Useful Links */}
      <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2 }}>
        <Typography variant="h6" align="center" gutterBottom>
          Useful Links
        </Typography>
        <div style={{ maxWidth: 300, textAlign: 'center' }}>
          <Typography variant="body2" align="center" sx={{ display: 'block', mb: 1 }}>
            <Link variant="inherit" color="inherit" onClick={() => navigate("/")} style={{ cursor: 'pointer', margin: '0 10px' }}>
              Home
            </Link>
          </Typography>
          <Typography variant="body2" align="center" sx={{ display: 'block', mb: 1 }}>
            <Link variant="inherit" color="inherit" onClick={() => navigate("/products")} style={{ cursor: 'pointer', margin: '0 10px' }}>
              Products
            </Link>
          </Typography>
          <Typography variant="body2" align="center" sx={{ display: 'block', mb: 1 }}>
            <Link variant="inherit" color="inherit" onClick={() => navigate("/about")} style={{ cursor: 'pointer', margin: '0 10px' }}>
              About Us
            </Link>
          </Typography>
          <Typography variant="body2" align="center" sx={{ display: 'block', mb: 1 }}>
            <Link variant="inherit" color="inherit" onClick={() => navigate("/refund-policy")} style={{ cursor: 'pointer', margin: '0 10px' }}>
            Refund Policy
            </Link>
          </Typography>
          <Typography variant="body2" align="center" sx={{ display: 'block', mb: 1 }}>
            <Link variant="inherit" color="inherit" onClick={() => navigate("/privaciy-policy")} style={{ cursor: 'pointer', margin: '0 10px' }}>
            Privacy Policy
            </Link>
          </Typography>
          <Typography variant="body2" align="center" sx={{ display: 'block', mb: 1 }}>
            <Link variant="inherit" color="inherit" onClick={() => navigate("/terms-condition")} style={{ cursor: 'pointer', margin: '0 10px' }}>
              Terms & Conditions
            </Link>
          </Typography>
        </div>
      </Grid>

      {/* Second Column: Social Media Links */}
      <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2 }}>
        <Typography variant="h6" align="center" gutterBottom>
          Connect with Us
        </Typography>
        <div className='flex justify-center mt-2'>
          <IconButton color="inherit" href={data?.footerDetails?.facebook} target="_blank">
            <Facebook />
          </IconButton>
          <IconButton color="inherit" href={data?.footerDetails?.youtube} target="_blank">
            <YouTube />
          </IconButton>
          <IconButton color="inherit" href={data?.footerDetails?.instagram} target="_blank">
            <Instagram />
          </IconButton>
          <IconButton color="inherit" href={data?.footerDetails?.pinterest} target="_blank">
            <Pinterest />
          </IconButton>
          <IconButton color="inherit" href={data?.footerDetails?.twitter} target="_blank">
            <Twitter />
          </IconButton>
          <IconButton color="inherit" href={data?.footerDetails?.playstore} target="_blank">
            <PlayArrow />
          </IconButton>
        </div>
      </Grid>

      {/* Third Column: Contact Information */}
      <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2 }}>
        <Typography variant="h6" align="center" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body2" align="center">
          {/* 110, First Floor, Western Business Center <br />
          New Palasia, Indore (M.P) <br /> */}
          {data?.footerDetails?.address}
        </Typography>
        <Typography variant="body2" align="center" sx={{marginTop:'20px'}}>
          Phone: {data?.footerDetails?.phoneNo} <br />
          Mobile: {data?.footerDetails?.mobileNo} <br />
          Email: <Link href="mailto:support@Softsolution.org" color="inherit">{data?.footerDetails?.email}</Link>
        </Typography>
        <Typography variant="body2" align="center">
          &copy; 2024 Soft Solutions. All Rights Reserved. <br />
        </Typography>
      </Grid>

      {/* Copyright Information */}
      {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography variant="body2" align="center">
          &copy; 2024 Soft Solutions. All Rights Reserved. <br />
        </Typography>
      </Grid> */}
    </Grid>
  );
};

export default Footer;
