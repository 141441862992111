import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";
import ProductReviewCard from "./ProductReviewCard";
import { Box, Button, Grid, LinearProgress, Rating, Paper, Typography } from "@mui/material";
import HomeProductCard from "../../Home/HomeProductCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findProductById } from "../../../../Redux/Customers/Product/Action";
import { addItemToCart } from "../../../../Redux/Customers/Cart/Action";
import { getAllReviews } from "../../../../Redux/Customers/Review/Action";
import { lengha_page1 } from "../../../../Data/Women/LenghaCholi";
import { gounsPage1 } from "../../../../Data/Gouns/gouns";
import BackdropComponent from "../../BackDrop/Backdrop";
import ReactImageMagnify from 'react-image-magnify';
import AuthModal from "../../Auth/AuthModal";
import AliceCarousel from "react-alice-carousel";
// import HomeProductCard from "../ProductCard/ProductCard";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import {
  addItemToLocalCart,
  updateLocalCartTotals
} from "../../../../Redux/Customers/LocalCart/CartActions";
import api, { API_BASE_URL } from "../../../../config/api";
import axios from 'axios';

const product = {
  name: "Basic Tee 6-Pack",
  price: "₹996",
  href: "#",
  breadcrumbs: [
    { id: 1, name: "Men", href: "#" },
    { id: 2, name: "Clothing", href: "#" },
  ],
  images: [
    {
      src: "https://tailwindui.com/img/ecommerce-images/product-page-02-secondary-product-shot.jpg",
      alt: "Two each of gray, white, and black shirts laying flat.",
    },
    {
      src: "https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-01.jpg",
      alt: "Model wearing plain black basic tee.",
    },
    {
      src: "https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-02.jpg",
      alt: "Model wearing plain gray basic tee.",
    },
    {
      src: "https://tailwindui.com/img/ecommerce-images/product-page-02-featured-product-shot.jpg",
      alt: "Model wearing plain white basic tee.",
    },
  ],
  colors: [
    { name: "White", class: "bg-white", selectedClass: "ring-gray-400" },
    { name: "Gray", class: "bg-gray-200", selectedClass: "ring-gray-400" },
    { name: "Black", class: "bg-gray-900", selectedClass: "ring-gray-900" },
  ],
  sizes: [
    { name: "S", inStock: true },
    { name: "M", inStock: true },
    { name: "L", inStock: true },
  ],
  description:
    'The Basic Tee 6-Pack allows you to fully express your vibrant personality with three grayscale options. Feeling adventurous? Put on a heather gray tee. Want to be a trendsetter? Try our exclusive colorway: "Black". Need to add an extra pop of color to your outfit? Our white tee has you covered.',
  highlights: [
    "Hand cut and sewn locally",
    "Dyed with our proprietary colors",
    "Pre-washed & pre-shrunk",
    "Ultra-soft 100% cotton",
  ],
  details:
    'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
};
const reviews = { href: "#", average: 4, totalCount: 117 };

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const responsive = {
  0: {
    items: 1,
    itemsFit: "contain",
  },
  568: {
    items: 2,
    itemsFit: "contain",
  },
  1024: {
    items: 4,
    itemsFit: "contain",
  },
};

export default function ProductDetails() {
  const { auth, cart } = useSelector((store) => store);
  const [selectedSize, setSelectedSize] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const [activeImage, setActiveImage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customersProduct, review } = useSelector((store) => store);
  const { productId } = useParams();
  const jwt = localStorage.getItem("jwt");
  const totalReviews = review?.reviews?.length
  const ExcellentRatings = review?.reviews.filter(data => data?.review?.rating > 4)?.length
  const GoodRatings = review?.reviews.filter(data => data?.review?.rating === 4)?.length
  const AverageRatings = review?.reviews.filter(data => data?.review?.rating >= 3 && data?.review?.rating < 4)?.length
  const BadRatings = review?.reviews.filter(data => data?.review?.rating <= 2)?.length
  const totalRatings = Math.ceil((review?.reviews.reduce((init, rev) => { return init + Number(rev?.review?.rating) }, 0) / totalReviews))
  // console.log("param",productId,customersProduct.product)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [relatedProduct, setrelatedProduct] = useState([]);
  const [relatedProdID, setrelatedProdID] = useState(null);


  useEffect(() => {
    setrelatedProdID(customersProduct?.product?.relatedProduct || null);
  }, [customersProduct])

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/products/Relatedproducts`, {
          relatedProduct: relatedProdID || null,
          ID: customersProduct?.product?._id,
          parentCategory: customersProduct?.product?.parentCategory
        });
        const data = response?.data;
        setrelatedProduct(data?.relatedProducts || []);
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };
    fetchRelatedProducts();
  }, [relatedProdID, customersProduct]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSetActiveImage = (image) => {
    setActiveImage(image);
  };

  useEffect(() => {
    setActiveImage(customersProduct.product?.imageUrl[0])
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }, [customersProduct]);

  const calculateCartTotals = (cartItems) => {
    let totalPrice = 0;
    let totalDiscountedPrice = 0;
    let totalItem = 0;

    for (const item of cartItems) {
      totalPrice += item.price * item.quantity;
      totalDiscountedPrice += item.discountedPrice * item.quantity;
      totalItem += item.quantity;
    }

    const discount = totalPrice - totalDiscountedPrice;

    return {
      cartItems,
      totalPrice,
      totalDiscountedPrice,
      totalItem,
      discount
    };
  };


  const handleSubmit = (e) => {
    // if (!auth?.user) {
    //   // handleOpen();
    //   const localCart = JSON.parse(localStorage.getItem('localCart')) || [];

    //   // Find the index of the product in the localCart
    //   const productIndex = localCart.findIndex(item => item.productId === productId);

    //   if (productIndex !== -1) {
    //     // If product exists in cart, increase its quantity
    //     localCart[productIndex].quantity += 1;
    //   } else {
    //     // If product does not exist in cart, add it with quantity 1
    //     localCart.push({
    //       productId,
    //       discountedPrice: customersProduct.product?.discountedPrice,
    //       price: customersProduct.product?.price,
    //       quantity: 1,
    //       product: customersProduct?.product
    //     });
    //   }

    //   // Save updated cart back to localStorage
    //   localStorage.setItem('localCart', JSON.stringify(localCart));
    //   const localCartDetails = calculateCartTotals(localCart);
    //   localStorage.setItem('localCartDetails', JSON.stringify(localCartDetails));
    //   // navigate('/cart')
    //   return;
    // }
    if (!auth?.user) {
      dispatch(addItemToLocalCart(customersProduct.product));
      // navigate('/cart');
      return;
    }
    const data = { productId };

    dispatch(addItemToCart({ data, jwt, navigate }));
  };

  const handleBuyNow = (e) => {
    // if (!auth?.user) {
    //   // handleOpen();
    //   const localCart = JSON.parse(localStorage.getItem('localCart')) || [];

    //     // Find the index of the product in the localCart
    //     const productIndex = localCart.findIndex(item => item.productId === product?._id);

    //     if (productIndex !== -1) {
    //         // If product exists in cart, increase its quantity
    //         localCart[productIndex].quantity += 1;
    //     } else {
    //         // If product does not exist in cart, add it with quantity 1
    //         localCart.push({
    //           productId: product?._id ,
    //             discountedPrice: product?.discountedPrice,
    //             price: product?.price,
    //             quantity: 1,
    //             product:product
    //         });
    //     }

    //     // Save updated cart back to localStorage
    //     localStorage.setItem('localCart', JSON.stringify(localCart));
    //     const localCartDetails = calculateCartTotals(localCart);
    //     localStorage.setItem('localCartDetails', JSON.stringify(localCartDetails));
    //     navigate('/cart')
    //     return;
    // }
    if (!auth?.user) {
      dispatch(addItemToLocalCart(customersProduct.product));
      navigate('/cart');
      return;
    }
    const data = { productId };

    dispatch(addItemToCart({ data, jwt, navigate }));
    navigate('/cart')
  };

  const handleOpen = () => {
    // navigate("/register")
    setOpenAuthModal(true);
  };
  const handleClose = () => {
    setOpenAuthModal(false);
  };

  useEffect(() => {
    const data = { productId: productId, jwt };
    dispatch(findProductById(data));
    dispatch(getAllReviews(productId));
  }, [productId]);

  // console.log("reviews ",review)

  const items = relatedProduct?.map((item, index) => (
    // <Paper key={index} elevation={4} sx={{ width: 'auto', height: 'auto', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
    <HomeProductCard product={item} />
    // </Paper>
  ));

  return (
    <div style={{ marginTop: '200px' }}>
      <AuthModal handleClose={handleClose} open={openAuthModal} />
      <BackdropComponent open={customersProduct?.loading} />
      <BackdropComponent open={cart?.loading} />

      <div className="bg-white lg:px-10 mt-3">
        <div className="">


          {/* product details */}

          <section className="grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-10 px-4">
            {/* Image gallery */}
            <div className="lg:col-span-6 flex flex-col lg:flex-row-reverse justify-start gap-2">
              <div className="rounded-lg w-[25rem] h-[25rem]" style={{
                border: '4px solid transparent',
                backgroundImage: 'linear-gradient(to bottom left, #d4aeab, white)',
                borderImageSlice: 1
              }}
              >
                {/* <img
                  src={activeImage || customersProduct.product?.imageUrl[0]}
                  alt={customersProduct.product?.title}
                  className="h-full w-full object-fill object-center"
                /> */}
                {/* <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: customersProduct.product?.title,
                      // isFluidWidth:true,
                      width: 400,
                      height: 400,
                      src: activeImage || customersProduct.product?.imageUrl[0],
                    },
                    largeImage: {
                      src: activeImage || customersProduct.product?.imageUrl[0],
                      width: 1024,
                      height: 1024
                    },
                    enlargedImageContainerStyle: {
                      background: '#fff',
                      zIndex: 1500,
                    },
                    enlargedImageContainerDimensions: {
                      width: '200%',
                      height: '100%'
                    }
                  }}
                /> */}
                {isMobile ? (
                  <img
                    alt={customersProduct.product?.title}
                    width={400}
                    height={400}
                    src={activeImage || customersProduct.product?.imageUrl[0]}
                    style={{ width: '100%', height: 'auto' }}
                  />
                ) : (
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: customersProduct.product?.title,
                        width: 400,
                        height: 400,
                        src: activeImage || customersProduct.product?.imageUrl[0],
                      },
                      largeImage: {
                        src: activeImage || customersProduct.product?.imageUrl[0],
                        width: 1024,
                        height: 1024,
                      },
                      enlargedImageContainerStyle: {
                        background: '#fff',
                        zIndex: 1500,
                      },
                      enlargedImageContainerDimensions: {
                        width: '200%',
                        height: '100%',
                      },
                    }}
                  />
                )}
                <div className="mt-2">
                  <div className="mt-2 space-y-6">
                    {isMobile ? (
                      <div className="flex" style={{ marginLeft: '150px' }}>
                        {customersProduct.product?.otherProducts?.map((color, index) => (
                          <div key={index} className="flex flex-col items-center mr-2 mt-10">
                            <img
                              className="h-8 w-8 object-fill object-center rounded-lg"
                              src={color?.imageUrl[0]}
                              alt={color?.color}
                              onClick={() => { navigate(`/product/${color?._id}`) }}
                            />
                            <span className="text-xs">{color?.color}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex">
                        {customersProduct.product?.otherProducts?.map((color, index) => (
                          <div key={index} className="flex flex-col items-center mr-2">
                            <img
                              className="h-20 w-20 object-fill object-center rounded-lg"
                              src={color?.imageUrl[0]}
                              alt={color?.color}
                              onClick={() => { navigate(`/product/${color?._id}`) }}
                            />
                            <span className="text-xs">{color?.color}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap flex-col w-[8rem] justify-start items-start gap-2 hover:cursor-pointer">
                {customersProduct.product?.imageUrl?.map((image, index) => (
                  image && (
                    <div
                      key={index}
                      onMouseOver={() => handleSetActiveImage(image)}
                      className="overflow-hidden rounded-lg w-[8rem] h-[8rem]"
                    >
                      <img
                        src={image}
                        alt={customersProduct.product?.title}
                        className="w-full h-full object-cover object-center"
                      />
                    </div>
                  )
                ))}
              </div>
            </div>

            {/* Product info */}

            <div className="lg:col-span-4 mx-auto w-full max-w-2xl px-4   ">
              <div className="lg:col-span-2">
                <h1 className="text-lg lg:text-xl font-semibold tracking-tight text-gray-900">
                  {customersProduct.product?.brand}
                </h1>
                <h1 className="text-md lg:text-lg tracking-tight text-gray-900 opacity-60 pt-1">
                  {customersProduct.product?.title}
                </h1>
              </div>

              {/* Options */}
              <div className="mt-4 lg:row-span-3 lg:mt-0">
                <h2 className="sr-only">Product information</h2>
                <div className="flex space-x-5 items-center text-md lg:text-lg tracking-tight text-gray-900 mt-6">
                  <p className="font-semibold">
                    ₹{Number(customersProduct.product?.discountedPrice)?.toFixed(2)}
                  </p>
                  <p className="opacity-50 line-through">
                    ₹{Number(customersProduct.product?.price)?.toFixed(2)}
                  </p>
                  <p className="text-green-600 font-semibold">
                    {Number(customersProduct.product?.discountPersent)?.toFixed(2)}% Off
                  </p>
                </div>

                <form className="">
                  {customersProduct?.product?.quantity < 1 ? (
                    <div className="w-full py-2 text-center bg-gray-300 cursor-not-allowed mt-4 text-white">
                      <p className="text-sm">Sold Out</p>
                    </div>
                  ) : !cart?.cartItems.find(
                    (item) =>
                      item?.product?._id === customersProduct?.product?._id
                  ) ? (
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <Button
                        variant="contained"
                        onClick={() => handleSubmit()}
                        sx={{ padding: ".6rem 1.5rem", marginTop: "1.5rem" }}
                      >
                        Add To Cart
                      </Button>

                      <Button
                        variant="contained"
                        onClick={() => handleBuyNow()}
                        sx={{ padding: ".6rem 1.5rem", marginTop: "1.5rem" }}
                      >
                        Buy Now
                      </Button>
                    </div>
                    ) : (
                    <Button
                      variant="contained"
                      className="bg-lime-500 text-white"
                      onClick={() => navigate("/cart")}
                      sx={{ padding: ".6rem 1.5rem", marginTop: "1.5rem" }}
                    >
                      View Cart
                    </Button>
                  )}
                </form>
              </div>

              <div className="py-10 lg:col-span-2 lg:col-start-1  lg:pb-16 lg:pt-6">
                {/* Description and details */}
                <div >
                  {/* <h3 className="sr-only">Description</h3> */}
                  <h3 className="text-sm font-medium text-gray-900">Description</h3>

                  <div className="space-y-6 min-h-[13rem]">
                    <p className="text-base text-gray-900 whitespace-pre-line">
                      {customersProduct.product?.description}
                    </p>

                  </div>
                </div>

                {/* <div className="mt-10">
                <h3 className="text-sm font-medium text-gray-900">
                  Highlights
                </h3>

                <div className="mt-4">
                  <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                    {product.highlights.map((highlight) => (
                      <li key={highlight} className="text-gray-400">
                        <span className="text-gray-600">{highlight}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div> */}
              </div>
            </div>

            {/* Ratings */}
            <div className="lg:col-span-2  w-full px-4 pb-16 ">
              <div className="flex items-center space-x-3 pb-4">
                <Rating
                  name="read-only"
                  value={totalRatings}
                  precision={0.5}
                  readOnly
                  size="small"
                />
                <p className="opacity-60 text-xs">{totalReviews} Ratings</p>
              </div>

              {[
                { label: "Excellent", value: ExcellentRatings, color: "success" },
                { label: "Very Good", value: GoodRatings, color: "success" },
                { label: "Average", value: AverageRatings, color: "warning" },
                { label: "Poor", value: BadRatings, color: "error" },
              ].map((rating) => (
                <Box key={rating.label} mb={0} className="w-full">
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={3}>
                      <p className="text-xs">{rating.label}</p>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={5}>
                      <LinearProgress
                        sx={{
                          bgcolor: "#d0d0d0",
                          borderRadius: 2,
                          height: 5,
                        }}
                        variant="determinate"
                        value={Math.ceil((rating.value / totalReviews) * 100)}
                        color={rating.color}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <p className="text-xs opacity-50 text-center">{rating.value}</p>
                    </Grid>
                  </Grid>
                </Box>
              ))}

              {review.reviews && review.reviews.length > 0 && (
                <div className="space-y-2 h-[240px] overflow-y-auto w-full mt-3">
                  {review.reviews?.map((item, i) => (
                    <ProductReviewCard item={item} key={i} />
                  ))}
                </div>
              )}
            </div>

          </section>
          {/* rating and review section */}
          {/* <section className="">
            <h1 className="font-semibold text-lg pb-4">
              Recent Review & Ratings
            </h1>

            <div className="border p-5">
              <Grid container spacing={7}>
                <Grid item xs={7}>
                  <div className="space-y-5 h-[270px] overflow-y-auto">
                    {review.reviews?.map((item, i) => (
                      <ProductReviewCard item={item} />
                    ))}
                  </div>
                </Grid>

                <Grid item xs={5}>
                  <h1 className="text-xl font-semibold pb-1">
                    Product Ratings
                  </h1>
                  <div className="flex items-center space-x-3 pb-10">
                    <Rating
                      name="read-only"
                      value={totalRatings}
                      precision={0.5}
                      readOnly
                    />

                    <p className="opacity-60">{totalReviews} Ratings</p>
                  </div>
                  <Box>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      gap={2}
                    >
                      <Grid xs={2}>
                        <p className="p-0">Excellent</p>
                      </Grid>
                      <Grid xs={7}>
                        <LinearProgress
                          className=""
                          sx={{
                            bgcolor: "#d0d0d0",
                            borderRadius: 4,
                            height: 7,
                          }}
                          variant="determinate"
                          value={Math.ceil((ExcellentRatings / totalReviews) * 100)}
                          color="success"
                        />
                      </Grid>
                      <Grid xs={2}>
                        <p className="opacity-50 p-2">{ExcellentRatings}</p>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      gap={2}
                    >
                      <Grid xs={2}>
                        <p className="p-0">Very Good</p>
                      </Grid>
                      <Grid xs={7}>
                        <LinearProgress
                          className=""
                          sx={{
                            bgcolor: "#d0d0d0",
                            borderRadius: 4,
                            height: 7,
                          }}
                          variant="determinate"
                          value={Math.ceil((GoodRatings / totalReviews) * 100)}
                          color="success"
                        />
                      </Grid>
                      <Grid xs={2}>
                        <p className="opacity-50 p-2">{GoodRatings}</p>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      gap={2}
                    >
                      <Grid xs={2}>
                        <p className="p-0">Avarage</p>
                      </Grid>
                      <Grid xs={7}>
                        <LinearProgress
                          className=""
                          sx={{
                            bgcolor: "#d0d0d0",
                            borderRadius: 4,
                            height: 7,
                            "& .MuiLinearProgress-bar": {
                              bgcolor: "#885c0a", // stroke color
                            },
                          }}
                          variant="determinate"
                          value={Math.ceil((AverageRatings / totalReviews) * 100)}
                          color="success"
                        />
                      </Grid>
                      <Grid xs={2}>
                        <p className="opacity-50 p-2">{AverageRatings}</p>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      gap={2}
                    >
                      <Grid xs={2}>
                        <p className="p-0">Poor</p>
                      </Grid>
                      <Grid xs={7}>
                        <LinearProgress
                          className=""
                          sx={{
                            bgcolor: "#d0d0d0",
                            borderRadius: 4,
                            height: 7,
                          }}
                          variant="determinate"
                          value={Math.ceil((BadRatings / totalReviews) * 100)}
                          color="error"
                        />
                      </Grid>
                      <Grid xs={2}>
                        <p className="opacity-50 p-2">{BadRatings}</p>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </section> */}

          {/* similer product */}
          {/* <section className=" pt-10">
          <h1 className="py-5 text-xl font-bold">Similer Products</h1>
          <div className="flex flex-wrap space-y-5">
            {gounsPage1.map((item) => (
              <HomeProductCard product={item} />
            ))}
          </div>
        </section> */}
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8 ">
        {relatedProduct.length > 0 && (
          <Typography variant="h5" component="h2" gutterBottom style={{ fontWeight: 'bold', marginBottom: '16px', textAlign: 'left' }}>
            Related Products
          </Typography>
        )}
        <div className="relative  p-1">

          <AliceCarousel
            autoHeight
            disableDotsControls
            items={items}
            responsive={responsive}
            animationType="slide"
            animationDuration={800}
            controlsStrategy="responsive"
            keyboardNavigation={true}
            renderPrevButton={({ isDisabled }) => {
              return <button disabled={isDisabled} className={`p-4 ${isDisabled && "hidden"} rounded-full  hover:bg-slate-400 opacity-75 hover:cursor-pointer absolute -left-5 top-1/4`}><ArrowCircleLeftIcon /></button>
            }}
            renderNextButton={({ isDisabled }) => {
              return <p className={`p-4 ${isDisabled && "hidden"} rounded-full  hover:bg-slate-400 opacity-75 hover:cursor-pointer  absolute -right-10 top-1/4 rotate-180`}><ArrowCircleLeftIcon /></p>
            }}
          />

        </div>
      </div>
    </div>
  );
}
