import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findProducts } from "../../../../Redux/Customers/Product/Action";
import {
    Button,
    Paper,
    List,
    MenuItem,
    Menu,
    TextField,
    Typography,
    Grid,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    getCategory,
    getCategoryById,
    getProductCategory,
    getSubCategory,
} from "../../../../Redux/Category/Action";
import {
    Category_level,
    DiscountPercentage,
    MaxPrice,
    MinPrice,
    SearchParmas,
} from "../../../../utils/utils";
import { GET_SUBCATEGORY_BY_ID_SUCCESS } from "../../../../Redux/Category/ActionTypes";

const Navbar = () => {
    const [showMore, setShowMore] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [hoveredCategory, setHoveredCategory] = useState(null); // State to track hovered category

    // const MainCategorys = [...] // Assuming this is your array of categories
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMoreCategoriesClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const [activeFilters, setActiveFilters] = useState([]);
    const [discountList, setDiscountList] = useState(DiscountPercentage);
    const { products, loading, totalCount } = useSelector((store) => store.customersProduct);

    const {
        category: MainCategory,
        subcategory,

    } = useSelector((store) => store?.adminCategory);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const searchParams = new URLSearchParams(location.search);
    const availability = searchParams.get("availability");
    const sort = searchParams.get("sort");
    const page = searchParams.get("page") || 1;
    const price_range_from = searchParams.get("price_range.from");
    const price_range_to = searchParams.get("price_range.to");
    const discount = searchParams.get("discount");

    // const [Loading,setLoading]=useState(false)
    const [productData, setProductData] = useState({
        topLavelCategory: "",
        secondLavelCategory: "",
        minPrice: 0,
        maxPrice: 10000,
        searchTerm: ""
    });

    const [isCatOpen, setIsCatOpen] = useState(true);
    const filters = [
        {
            name: `${price_range_from
                ? price_range_from +
                "-" +
                (price_range_to ||
                    MaxPrice.find((price) => price?.value === 10000)?.title)
                : MinPrice.find((price) => !price.value).title +
                "-" +
                price_range_to ||
                MaxPrice.find((price) => price?.value === 10000)?.title
                }`,
            isActive: price_range_from || price_range_to,
            value: SearchParmas.PRICE_RANGE_FROM || SearchParmas.PRICE_RANGE_TO,
        },

        {
            name: `Discount-${DiscountPercentage.find(
                (dis) => Number(dis?.value) === Number(discount)
            )?.title
                }`,
            isActive: discount,
            value: SearchParmas.DISCOUNT,
        },
    ];

    const [IsLargeScreen, setIsLargeScreen] = useState(true);

    useEffect(() => {
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 1024); // Example breakpoint for lg screens
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            const gridHeight = document.querySelector('.fixed-grid-header').offsetHeight + 200;
            const isLargeScreen = window.innerWidth >= 1024; // lg breakpoint is typically 1024px

            if (isLargeScreen && offset > gridHeight) {

                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const fixedStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.1s ease-in-out',
        background: 'linear-gradient(to right, #ffffff, #ffffff)',
        marginTop: '155px',
        padding: '0.5rem',
    }; 

    const relativeStyle = {
        position: 'relative',
        transition: 'all 0.1s ease-in-out',
        background: 'linear-gradient(to right, #ffffff, #ffffff)',
        padding: '0.5rem',
    };

    const handleMouseEnter = async (categoryId) => {
        await handleCatSelect(categoryId)
        setHoveredCategory(categoryId);
    };

    const handleMouseLeave = () => {
        setHoveredCategory(null);
    };

    useEffect(() => {
        // setFilterValue({ availability, category, sort })
        const data = {
            category: productData?.category || "",
            colors: [],
            sizes: [],
            minPrice:
                Number(
                    MinPrice.find((data) => data.title === price_range_from)?.value
                ) || 0,
            maxPrice:
                Number(MaxPrice.find((data) => data.title === price_range_to)?.value) ||
                "",
            minDiscount: discount || "",
            sort: sort || "",
            pageNumber: page || 1,
            pageSize: 20,
            stock: availability,
            parent_category: productData?.parent_category || "",
            searchTerm: searchTerm,
        };

        let active_filters = filters.filter((data) => data?.isActive);
        setActiveFilters(active_filters);
        setProductData((data) => {
            return {
                ...data,
                minPrice: Number(
                    MinPrice.find((data) => data.title === price_range_from)?.value || 0
                ),
                maxPrice: Number(
                    MaxPrice.find((data) => data.title === price_range_to)?.value || 10000
                ),
            };
        });

        let updated_list = DiscountPercentage.map((dis) =>
            Number(dis?.value) === Number(discount) ? { ...dis, checked: true } : dis
        );

        setDiscountList(updated_list);
        dispatch(findProducts(data));
    }, [
        availability,
        productData?.secondLavelCategory,
        sort,
        page,
        products.deleteProduct,
        price_range_from,
        price_range_to,
        discount,

        productData?.parent_category,
        productData?.category,
        searchTerm
    ]);
    useEffect(() => {
        let data2 = {
            level: Category_level?.TOP,
            pageNumber: 1,
            pageSize: 0,
            searchTerm: ""
        };
        dispatch(getCategory(data2));
    }, []);

    const handleCatSelect = (e) => {
        dispatch({
            type: GET_SUBCATEGORY_BY_ID_SUCCESS,
            payload: [],
        });
        // handleChange(e, valueAssociate);
        dispatch(
            getCategoryById({
                parentCatId: e,
                level: Category_level?.SECONDARY,
            })
        );
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    const handleChange = (e, valueAssociate) => {
        const { name, value } = e?.target;
        console.log(name, value);
        if (name === "parent_category" || name === "category") {
            return setProductData((pData) => ({
                ...pData,
                [name]: value,
                // searchTerm:name==="category"?"": valueAssociate,
                // category:name==="parent_category"?"":value
            }));
        }

        // setProductData((pData) => ({ ...pData, [name]: value }));
        else if (name === "searchTerm") {
            setSearchTerm(value); // Update searchTerm state
        } else {
            setProductData((pData) => ({ ...pData, [name]: value }));
        }
    };

    const handleCategoryButtonClick = (categoryId) => {
        setProductData((pData) => ({
            ...pData,
            parent_category: categoryId,
            category: ""
        }));
    };

    const handleSubcategoryClick = (subcategoryId) => {
        setProductData((prevState) => ({
            ...prevState,
            parent_category: "",
            category: subcategoryId
        }));
    };

    const handleAllCategoriesClick = () => {
        setProductData((pData) => ({
            ...pData,
            parent_category: "",
            category: ""
        }));
    };

    return (
        <Grid
            container
            spacing={0}
            alignItems="center"
            style={fixedStyle}
            className="fixed-grid-header"
        >
            {/* <Grid item xs={10} container spacing={2}> */}
            <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                {/* <Grid item> */}
                <Button
                    className="m-1 py-1 px-2 rounded-lg"
                    onClick={handleAllCategoriesClick}
                    sx={{ color: 'black', fontSize: IsLargeScreen? '12px': '10px'}}
                >
                    All
                </Button>
                {/* </Grid> */}

                {MainCategory.slice(0, 5).map((cat) => (
                    <div item key={cat?._id}>
                        <div
                            className="relative"
                            onMouseEnter={() => handleMouseEnter(cat?._id)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Button
                                className="m-1 py-1 px-2 rounded-lg"
                                onClick={() => handleCategoryButtonClick(cat?._id)}
                                sx={{ display: 'flex', flexDirection: 'row', color: 'black', fontSize: IsLargeScreen? '12px': '10px' }}
                            >
                                {cat?.name} <KeyboardArrowDownIcon />
                            </Button>
                            {hoveredCategory === cat?._id && subcategory.length > 0 && (
                                <Paper
                                    sx={{
                                        position: 'absolute',
                                        backgroundColor: 'white',
                                        borderRadius: '0.375rem',
                                        padding: 0,
                                        marginTop: 0,
                                        boxShadow: 'none',
                                        zIndex: 9999,
                                    }}
                                >
                                    <List style={{ padding: 0 }}>
                                        {subcategory.map((subCat) => (
                                            <MenuItem
                                                key={subCat._id}
                                                onClick={() => handleSubcategoryClick(subCat?._id)}
                                                sx={{ color: 'black', fontSize: '12px' }}
                                            >
                                                {subCat?.name}
                                            </MenuItem>
                                        ))}
                                    </List>
                                </Paper>
                            )}
                        </div>
                    </div>
                ))}
                {MainCategory.length > 5 && (
                    // <Grid item>
                    <Button
                        className="m-1 py-1 px-2 rounded-lg bg-white-200 text-white-700"
                        onClick={handleMoreCategoriesClick}
                        sx={{ display: 'flex', flexDirection: 'row', color: 'black', fontSize: IsLargeScreen? '12px': '10px' }}
                    >
                        More <KeyboardArrowDownIcon />
                    </Button>
                    // </Grid>
                )}
                {/* </Grid> */}
                {/* <Grid item xs={12} sm={12} md={2} lg={2} style={{ textAlign: 'center' }}> */}
                <TextField
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    sx={{ marginLeft: 'auto', width: IsLargeScreen? '200px': '120px', backgroundColor: '#fff', borderRadius: '4px' }}
                    InputProps={{ style: { fontSize: '12px' } }}
                />
            </Grid>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {MainCategory.slice(5).map((cat) => (
                    <MenuItem
                        key={cat?._id}
                        onClick={() => {
                            handleCategoryButtonClick(cat?._id);
                            handleMenuClose();
                        }}
                    >
                        {capitalizeFirstLetter(cat?.name)}
                    </MenuItem>
                ))}
            </Menu>
        </Grid>
    );
};

export default Navbar;
