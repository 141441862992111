import React, { useState } from 'react'
import BackdropComponent from '../BackDrop/Backdrop'
import { Button, CardHeader, Grid, InputLabel, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OTPInput from 'react-otp-input';

import { verifyOtp } from '../../../Redux/Auth/Action';

const VerifyOtp = () => {
    const { auth } = useSelector((store) => store);
    const [otp,setOtp]= useState()
    const dispatch =useDispatch()
    const navigate= useNavigate()
    const handleSubmit=(e)=>{
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        const userData={
            email: data.get("email")?.toLowerCase(),
            password: data.get("password"),
            otp:otp
           
          }
        dispatch(verifyOtp(userData,navigate))
    }
  return (
    <div className="">
        <BackdropComponent open={auth?.isLoading} />
        <CardHeader
          title="Change Password"
          className='mx-auto text-center text-base'
          sx={{
            pt: 0,
            alignItems: "center",
            "& .MuiCardHeader-action": { mt: 0.6,mx:"auto" },
          }}
        />
        
      <form onSubmit={handleSubmit}>
      <Grid item xs={12} my={3}>
        <InputLabel>OTP</InputLabel>
      <OTPInput
      value={otp}
      onChange={setOtp}
      
      numInputs={4}
      containerStyle={"w-4/5 mx-auto justify-between"}
      inputStyle={{border:"1px solid", width:"20%",paddingBlock:"10px",borderRadius:"10px"}}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input className='border p-2' {...props} />}
    />
    </Grid>
    <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12} my={3}>
            <TextField
              required
              id="password"
              name="password"
              label="Password"
              fullWidth
              autoComplete="given-name"
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className="bg-[#9155FD] w-full"
              type="submit"
              variant="contained"
              size="large"
              sx={{padding:".8rem 0"}}
            >
              Submit
            </Button>
          </Grid>
      </form>
      </div>
  )
}

export default VerifyOtp