export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';

// Action types for create category
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const REMOVE_CATEGORY_REQUEST = 'REMOVE_CATEGORY_REQUEST';
export const REMOVE_CATEGORY_SUCCESS = 'REMOVE_CATEGORY_SUCCESS';
export const REMOVE_CATEGORY_FAILURE = 'REMOVE_CATEGORY_FAILURE';

export const GET_SUBCATEGORY_REQUEST = 'GET_SUBCATEGORY_REQUEST';
export const GET_SUBCATEGORY_SUCCESS = 'GET_SUBCATEGORY_SUCCESS';
export const GET_SUBCATEGORY_FAILURE = 'GET_SUBCATEGORY_FAILURE';

export const GET_SUBCATEGORY_BY_ID_REQUEST = 'GET_SUBCATEGORY_BY_ID_REQUEST';
export const GET_SUBCATEGORY_BY_ID_SUCCESS = 'GET_SUBCATEGORY_BY_ID_SUCCESS';
export const GET_SUBCATEGORY_BY_ID_FAILURE = 'GET_SUBCATEGORY_BY_ID_FAILURE';

// Action types for create subcategory
export const CREATE_SUBCATEGORY_REQUEST = 'CREATE_SUBCATEGORY_REQUEST';
export const CREATE_SUBCATEGORY_SUCCESS = 'CREATE_SUBCATEGORY_SUCCESS';
export const CREATE_SUBCATEGORY_FAILURE = 'CREATE_SUBCATEGORY_FAILURE';

export const UPDATE_SUBCATEGORY_REQUEST = 'UPDATE_SUBCATEGORY_REQUEST';
export const UPDATE_SUBCATEGORY_SUCCESS = 'UPDATE_SUBCATEGORY_SUCCESS';
export const UPDATE_SUBCATEGORY_FAILURE = 'UPDATE_SUBCATEGORY_FAILURE';

export const REMOVE_SUBCATEGORY_REQUEST = 'REMOVE_SUBCATEGORY_REQUEST';
export const REMOVE_SUBCATEGORY_SUCCESS = 'REMOVE_SUBCATEGORY_SUCCESS';
export const REMOVE_SUBCATEGORY_FAILURE = 'REMOVE_SUBCATEGORY_FAILURE';



// Action types for create subcategory
export const GET_PRODUCT_CATEGORY_REQUEST = 'GET_PRODUCT_CATEGORY_REQUEST';
export const GET_PRODUCT_CATEGORY_SUCCESS = 'GET_PRODUCT_CATEGORY_SUCCESS';
export const GET_PRODUCT_CATEGORY_FAILURE = 'GET_PRODUCT_CATEGORY_FAILURE';

export const GET_PRODUCT_CATEGORY_BY_ID_REQUEST = 'GET_PRODUCT_CATEGORY_BY_ID_REQUEST';
export const GET_PRODUCT_CATEGORY_BY_ID_SUCCESS = 'GET_PRODUCT_CATEGORY_BY_ID_SUCCESS';
export const GET_PRODUCT_CATEGORY_BY_ID_FAILURE = 'GET_PRODUCT_CATEGORY_BY_ID_FAILURE';

export const CREATE_PRODUCT_CATEGORY_REQUEST = 'CREATE_PRODUCT_CATEGORY_REQUEST';
export const CREATE_PRODUCT_CATEGORY_SUCCESS = 'CREATE_PRODUCT_CATEGORY_SUCCESS';
export const CREATE_PRODUCT_CATEGORY_FAILURE = 'CREATE_PRODUCT_CATEGORY_FAILURE';

export const UPDATE_PRODUCT_CATEGORY_REQUEST = 'UPDATE_PRODUCT_CATEGORY_REQUEST';
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = 'UPDATE_PRODUCT_CATEGORY_SUCCESS';
export const UPDATE_PRODUCT_CATEGORY_FAILURE = 'UPDATE_PRODUCT_CATEGORY_FAILURE';

export const REMOVE_PROUDUCT_CATEGORY_REQUEST = 'REMOVE_PROUDUCT_CATEGORY_REQUEST';
export const REMOVE_PROUDUCT_CATEGORY_SUCCESS = 'REMOVE_PROUDUCT_CATEGORY_SUCCESS';
export const REMOVE_PROUDUCT_CATEGORY_FAILURE = 'REMOVE_PROUDUCT_CATEGORY_FAILURE';