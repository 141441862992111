import React from 'react'
import BackdropComponent from '../BackDrop/Backdrop'
import { Button, CardHeader, Grid, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail } from '../../../Redux/Auth/Action';
import { useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
    const { auth } = useSelector((store) => store);
    const dispatch =useDispatch()
    const navigate= useNavigate()
    const handleSubmit=(e)=>{
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        
        dispatch(verifyEmail({email:data?.get("email")},navigate))
    }
  return (
    
         <div className="">
        <BackdropComponent open={auth?.isLoading} />
        <CardHeader
          title="Verify Email"
          className='mx-auto text-center text-base'
          sx={{
            pt: 0,
            alignItems: "center",
            "& .MuiCardHeader-action": { mt: 0.6,mx:"auto" },
          }}
        />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              placeholder='Enter registered email here'
              label="Email"
              fullWidth
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className="bg-[#9155FD] w-full"
              type="submit"
              variant="contained"
              size="large"
              sx={{padding:".8rem 0"}}
            >
              Submit
            </Button>
          </Grid>
          </Grid>

          
       
      </form>

    </div>
  )
}

export default VerifyEmail