// import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
// import React from 'react'

// const Sorting = ({searchParams,navigate,sort}) => {
//     const [anchorElUser, setAnchorElUser] = React.useState(null);
//     const settings = [{title:'Price -- Low to High',value:"price_low"}, {title:'Price -- High to Low',value:"price_high"},];
//     const handleOpenUserMenu = (event) => {
//         setAnchorElUser(event.currentTarget);
//       };

//       const handleSortChange = (event, value) => {
//         searchParams.set("sort", value);
//         const query = searchParams.toString();
//         navigate({ search: `?${query}` });
//       };
//       const handleCloseUserMenu = (e,value) => {
//         handleSortChange(e,value)
//         setAnchorElUser(null);
//       };
//       console.log({sort})
//   return (
//     <div className='mt-1'>
//            <Box sx={{ display:"flex",flexGrow: 0,alignItems:"center",gap:"1rem" }}>

//             <Typography textAlign="center" sx={{ paddingBottom: 1 }}>Sort By</Typography>
//             {/* <Menu
//               sx={{ mt: '45px' }}
//               id="menu-appbar"
//               anchorEl={anchorElUser}
//               anchorOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//               }}
//               open={Boolean(anchorElUser)}
//               onClose={handleCloseUserMenu}
//             >
//               {settings.map((setting) => (
//                 <MenuItem key={setting} onClick={(e)=>handleCloseUserMenu(e,setting?.value)}>
//                   <Typography textAlign="center">{setting?.title}</Typography>
//                 </MenuItem>
//               ))}`
//             </Menu> */}
//             <div className='flex translate-y-0.5 gap-2'>
//               {settings.map((setting) => (
//                   <div key={setting} className={`py-1 px-2  hover:cursor-pointer hover:border-b-2 ${sort===setting?.value && "border-b-2 border-b-blue-800 font-medium"} hover:border-b-blue-800`} onClick={(e)=>handleCloseUserMenu(e,setting?.value)}>
//                     <p className='text-sm ' textAlign="center">{setting?.title}</p>
//                   </div>
//                 ))}
//             </div>
//           </Box>
//     </div>
//   )
// }

// export default Sorting


import React, { useState } from 'react';
import { Box, Typography, Button, Popover } from '@mui/material';
import MultiRangeSlider from 'multi-range-slider-react';

const Sorting = ({ searchParams, navigate, sort, discountList, setDiscountList, productData, setProductData, MinPrice, MaxPrice, handleSetSearchParams, handleRangeInput }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const settings = [
    { title: 'Price -- Low to High', value: 'price_low' },
    { title: 'Price -- High to Low', value: 'price_high' },
  ];

  const handleSortChange = (event, value) => {
    searchParams.set('sort', value);
    const query = searchParams.toString();
    navigate({ search: `?${query}` });
  };

  const handlePriceClick = (event) => {
    setAnchorEl(event.currentTarget);

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'price-popover' : undefined;

  return (
    <div className='mt-1'>
      <Box sx={{ display: 'flex', flexGrow: 0, alignItems: 'center', gap: '1rem' }}>
        <Typography textAlign='center' sx={{ paddingBottom: 2 }}>Sort By</Typography>
        <div className='flex translate-y-0.5 gap-2'>
          {settings.map((setting) => (
            <div key={setting.value} className={`py-1 px-2 hover:cursor-pointer hover:border-b-2 ${sort === setting.value && 'border-b-2 border-b-blue-800 font-medium'} hover:border-b-blue-800`} onClick={(e) => handleSortChange(e, setting.value)}>
              <p className='text-sm' textAlign='center'>{setting.title}</p>
            </div>
          ))}
          <div className='mt-0'>
            <select
              id='discount'
              name='discount'
              value={productData.discount}
              onChange={(e) => {
                const { value } = e.target;
                setProductData((prevState) => ({
                  ...prevState,
                  discount: value,
                }));
                handleSetSearchParams('discount', value);
              }}
              className='bg-white-50 text-white-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1'
            >
              <option value=''>Select Discount</option>
              {discountList.map((discount) => (
                <option key={discount.value} value={discount.value}>
                  {discount.title}
                </option>
              ))}
            </select>
          </div>
          <div className='mt-0'>
          <button  onClick={handlePriceClick} style={{marginLeft:'10px'}}>Price Range</button>
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{ p: 2, width: 300 }}>
              <Typography variant="h6" component="h2">
                Select Price Range
              </Typography>
              <MultiRangeSlider
                min={0}
                max={10000}
                minValue={productData.minPrice}
                maxValue={productData.maxPrice}
                step={1000}
                label={false}
                ruler={false}
                barInnerColor='#e8d5a2'
                className='border-none'
                stepOnly={true}
                onChange={(e) => handleRangeInput(e)}
              />
              <div className='flex items-center justify-between mt-3'>
                <select
                  id='minPrice'
                  name='minPrice'
                  value={productData.minPrice}
                  onChange={(e) => {
                    setProductData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                    handleSetSearchParams('price_range.from', MinPrice.find((data) => data.value === Number(e.target.value)).title);
                  }}
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                >
                  {MinPrice.map((data) => (
                    <option key={data.value} value={data.value}>
                      {data.title}
                    </option>
                  ))}
                </select>
                <p className='text-sm mx-2'>to</p>
                <select
                  id='maxPrice'
                  name='maxPrice'
                  value={productData.maxPrice}
                  onChange={(e) => {
                    setProductData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                    handleSetSearchParams('price_range.to', MaxPrice.find((data) => data.value === Number(e.target.value)).title);
                  }}
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                >
                  {MaxPrice.filter((data) => data.value > Number(productData.minPrice)).map((data) => (
                    <option key={data.value} value={data.value}>
                      {data.title}
                    </option>
                  ))}
                </select>
              </div>
            </Box>
          </Popover>
        </div>
      </Box>
    </div>
  );
};

export default Sorting;
