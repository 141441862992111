
import axios from 'axios';
const DEPLOYED='http://ecombackend.ap-south-1.elasticbeanstalk.com'
// const LOCALHOST='https://ecombackend-dgdu.onrender.com'
// const LOCALHOST='https://f15b-103-15-67-130.ngrok-free.app'
// const LOCALHOST='http://65.2.11.57:5000'
const LOCALHOST='https://raghunandanrice.com'
// const LOCALHOST='http://13.201.190.229:5000' //raghunandan
// const LOCALHOST='http://192.168.1.10:3002'

export const API_BASE_URL = LOCALHOST;

const api = axios.create({
  baseURL: API_BASE_URL,
});

const token = localStorage.getItem('jwt');

api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

api.defaults.headers.post['Content-Type'] = 'application/json';

export default api;
