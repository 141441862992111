import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Grid, Paper, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import api, { API_BASE_URL } from "../../../config/api";

const AppSettingsForm = () => {
  const [footerDetails, setFooterDetails] = useState({
    address: '',
    mobileNo: '',
    phoneNo: '',
    email: '',
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: '',
    pinterest: '',
    playstore: '',
    footerBgColor: '',
    footerTextColor: ''
  });
  const [headerColor, setHeaderColor] = useState('#ffffff');
  const [tagLine, setTagLine] = useState('');
  const [buttonColor, setButtonColor] = useState('#007bff');
  const [ourvision, setourvision] = useState('');
  const [ourmission, setourmission] = useState('');
  const [DeliveryCharges, setDeliveryCharges] = useState('');
  const [businessName, setbusinessName] = useState('');
  const [NewRegistrationPoints, setNewRegistrationPoints] = useState('');
  const [RefralCodePointPercentage, setRefralCodePointPercentage] = useState('');
  const [domain, setdomain] = useState('');
  const [whatssapp, setwhatssapp] = useState('');

  const [offermarquee, setoffermarquee] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/appdetails/appDetailsGet`);
        const data = response?.data;
        setFooterDetails(data?.footerDetails);
        setHeaderColor(data?.headerColor);
        setTagLine(data?.tagLine);
        setButtonColor(data?.buttonColor);
        setourvision(data?.ourvision);
        setourmission(data?.ourmission);
        setbusinessName(data?.businessName);
        setDeliveryCharges(data?.DeliveryCharges);
        setNewRegistrationPoints(data?.NewRegistrationPoints);
        setRefralCodePointPercentage(data?.RefralCodePointPercentage);
        setdomain(data?.domain);
        setoffermarquee(data?.offermarquee);
        setwhatssapp(data?.whatssapp);
      } catch (error) {
        console.error('Error fetching details:', error);
        setSnackbarSeverity('error');
        setSnackbarMessage('Error fetching application details');
        setSnackbarOpen(true);
      }
    };

    fetchSettings();
  }, []);

  const handleFooterDetailsChange = (event) => {
    const { name, value } = event.target;
    setFooterDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleTagLineChange = (event) => {
    setTagLine(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      footerDetails,
      headerColor,
      tagLine,
      buttonColor,
      ourvision,
      ourmission,
      businessName,
      DeliveryCharges,
      NewRegistrationPoints,
      RefralCodePointPercentage,
      domain,
      offermarquee,
      whatssapp
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/api/admin/appdetails/appDetailsUpdate`, data);
      setSnackbarSeverity('success');
      setSnackbarMessage('Details updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating Details:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Error updating Details');
      setSnackbarOpen(true);
    }
  };

  const appDetailsString = localStorage.getItem('appDetails');
  const appDetails = JSON.parse(appDetailsString);

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Update Application Details
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Address"
              name="address"
              value={footerDetails?.address}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Mobile No"
              name="mobileNo"
              value={footerDetails?.mobileNo}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Phone No"
              name="phoneNo"
              value={footerDetails?.phoneNo}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Email"
              name="email"
              value={footerDetails?.email}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Playstore Link"
              name="playstore"
              value={footerDetails?.playstore}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Facebook Link"
              name="facebook"
              value={footerDetails?.facebook}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Twitter Link"
              name="twitter"
              value={footerDetails?.twitter}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Instagram Link"
              name="instagram"
              value={footerDetails?.instagram}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="YouTube Link"
              name="youtube"
              value={footerDetails?.youtube}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Pinterest Link"
              name="pinterest"
              value={footerDetails?.pinterest}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Footer Background Color"
              name="footerBgColor"
              value={footerDetails?.footerBgColor}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Footer Text Color"
              name="footerTextColor"
              value={footerDetails?.footerTextColor}
              onChange={handleFooterDetailsChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Offer marquee"
              name="offermarquee"
              value={offermarquee}
              onChange={(e) => setoffermarquee(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="WhatsApp Number"
              name="whatssapp"
              value={whatssapp}
              onChange={(e) => setwhatssapp(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Header Color Code"
              value={headerColor}
              onChange={(e) => setHeaderColor(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Tag Line"
              value={tagLine}
              onChange={handleTagLineChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Product Card Button Color"
              value={buttonColor}
              onChange={(e) => setButtonColor(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Our Vision"
              value={ourvision}
              onChange={(e) => setourvision(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Our Mission"
              value={ourmission}
              onChange={(e) => setourmission(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Business Name"
              value={businessName}
              onChange={(e) => setbusinessName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Domain Name"
              value={domain}
              onChange={(e) => setdomain(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="Delivery Charges"
              type='Number'
              value={DeliveryCharges}
              onChange={(e) => setDeliveryCharges(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label="New Registration Points"
              type='Number'
              value={NewRegistrationPoints}
              onChange={(e) => setNewRegistrationPoints(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              type='Number'
              label="Refral Code Points Percentage"
              value={RefralCodePointPercentage}
              onChange={(e) => setRefralCodePointPercentage(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained">
              Update Details
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AppSettingsForm;
