import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import api, { API_BASE_URL } from "../../../config/api";

const CouponTable = () => {
    const [coupons, setCoupons] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentCoupon, setCurrentCoupon] = useState({ id: null, code: '', discount: '' });
    const [editMode, setEditMode] = useState(false);

    // Fetch coupons from API
    const Datafetch = () => {
        axios.get(`${API_BASE_URL}/api/admin/discountCoupens/discountCoupensGet`)
            .then(response => setCoupons(response.data))
            .catch(error => console.error('Error fetching coupons:', error));
    }

    useEffect(() => {
        Datafetch();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setCurrentCoupon({ code: '', discount: '' });
        setEditMode(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentCoupon({ ...currentCoupon, [name]: value });
    };

    const handleAddCoupon = () => {
        if (editMode) {
            axios.post(`${API_BASE_URL}/api/admin/discountCoupens/discountCoupensUpdate`, currentCoupon)
                .then(response => {
                    Datafetch();
                    handleClose();
                })
                .catch(error => console.error('Error updating coupon:', error));
        } else {
            axios.post(`${API_BASE_URL}/api/admin/discountCoupens/discountCoupensAdd`, currentCoupon)
                .then(response => {
                    Datafetch();
                    handleClose();
                })
                .catch(error => console.error('Error adding coupon:', error));
        }
    };

    const handleEditCoupon = (coupon) => {
        setCurrentCoupon(coupon);
        setEditMode(true);
        handleOpen();
    };

    const handleDeleteCoupon = async (_id) => {
        await axios.delete(`${API_BASE_URL}/api/admin/discountCoupens/discountCoupensDelete/${_id}`)
            .then(() => {
                Datafetch();
            })
            .catch(error => console.error('Error deleting coupon:', error));
    };

    return (
        <Box>
            <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<Add />}>
                Add Coupon
            </Button>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {coupons.map((coupon) => (
                            <TableRow key={coupon.id}>
                                <TableCell>{coupon.code}</TableCell>
                                <TableCell>{coupon.discount}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleEditCoupon(coupon)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => handleDeleteCoupon(coupon._id)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{editMode ? 'Edit Coupon' : 'Add Coupon'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="code"
                        label="Code"
                        type="text"
                        fullWidth
                        value={currentCoupon.code}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="discount"
                        label="Discount"
                        type="text"
                        fullWidth
                        value={currentCoupon.discount}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddCoupon} color="primary">
                        {editMode ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CouponTable;
