import React from "react";
import { useState } from "react";
import "./ProductCard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToLocalCart,
  updateLocalCartTotals
} from "../../../../Redux/Customers/LocalCart/CartActions";

import {
  addItemToCart,
  getCart,
} from "../../../../Redux/Customers/Cart/Action";
import BackdropComponent from "../../BackDrop/Backdrop";
import Slider from "../ProductPage/components/Slider";
import { Button, Paper } from "@mui/material";
import AuthModal from "../../Auth/AuthModal";

const ProductCard = ({ product }) => {
  const {
    title,
    brand,
    imageUrl,
    price,
    discountedPrice,
    color,
    discountPersent,
    description
  } = product;
  const { auth, cart } = useSelector((store) => store);
  const { cartItems } = cart;
  const jwt = localStorage.getItem("jwt");
  const dispatch = useDispatch();
  console.log({ product });
  const navigate = useNavigate();
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const handleNavigate = () => {
    navigate(`/product/${product?._id}`);
  };

  const truncateText = (text, charLimit) => {
    return text.length > charLimit ? text.slice(0, charLimit) + '...' : text;
  };

  // const handleSubmit = async (e) => {
  //   if (!auth?.user) {
  //     handleOpen();
  //     return;
  //   }
  //   const data = { productId: product?._id };
  //   await dispatch(addItemToCart({ data, jwt, navigate }));
  //   // navigate('/cart')
  // };

  const calculateCartTotals = (cartItems) => {
    let totalPrice = 0;
    let totalDiscountedPrice = 0;
    let totalItem = 0;
  
    for (const item of cartItems) {
      totalPrice += item.price * item.quantity;
      totalDiscountedPrice += item.discountedPrice * item.quantity;
      totalItem += item.quantity;
    }
  
    const discount = totalPrice - totalDiscountedPrice;
  
    return {
      cartItems,
      totalPrice,
      totalDiscountedPrice,
      totalItem,
      discount
    };
  };

  const handleSubmit = (e) => {
    // if (!auth?.user) {
    //   // handleOpen();
    //   const localCart = JSON.parse(localStorage.getItem('localCart')) || [];
        
    //     // Find the index of the product in the localCart
    //     const productIndex = localCart.findIndex(item => item.productId === product?._id);
        
    //     if (productIndex !== -1) {
    //         // If product exists in cart, increase its quantity
    //         localCart[productIndex].quantity += 1;
    //     } else {
    //         // If product does not exist in cart, add it with quantity 1
    //         localCart.push({
    //           productId: product?._id ,
    //             discountedPrice: product?.discountedPrice,
    //             price: product?.price,
    //             quantity: 1,
    //             product:product
    //         });
    //     }

    //     // Save updated cart back to localStorage
    //     localStorage.setItem('localCart', JSON.stringify(localCart));
    //     const localCartDetails = calculateCartTotals(localCart);
    //     localStorage.setItem('localCartDetails', JSON.stringify(localCartDetails));
    //     // navigate('/cart')
    //     return;
    // }
    if (!auth?.user) {
      dispatch(addItemToLocalCart(product));
      return;
    }
    const data = { productId: product?._id  };

    dispatch(addItemToCart({ data, jwt, navigate }));
  };

  const handleBuyNow = (e) => {
    // if (!auth?.user) {
    //   // handleOpen();
    //   const localCart = JSON.parse(localStorage.getItem('localCart')) || [];
        
    //     // Find the index of the product in the localCart
    //     const productIndex = localCart.findIndex(item => item.productId === product?._id);
        
    //     if (productIndex !== -1) {
    //         // If product exists in cart, increase its quantity
    //         localCart[productIndex].quantity += 1;
    //     } else {
    //         // If product does not exist in cart, add it with quantity 1
    //         localCart.push({
    //           productId: product?._id ,
    //             discountedPrice: product?.discountedPrice,
    //             price: product?.price,
    //             quantity: 1,
    //             product:product
    //         });
    //     }

    //     // Save updated cart back to localStorage
    //     localStorage.setItem('localCart', JSON.stringify(localCart));
    //     const localCartDetails = calculateCartTotals(localCart);
    //     localStorage.setItem('localCartDetails', JSON.stringify(localCartDetails));
    //     navigate('/cart')
    //     return;
    // }
    if (!auth?.user) {
      dispatch(addItemToLocalCart(product));
      navigate('/cart');
      return;
    }
    const data = { productId: product?._id  };

    dispatch(addItemToCart({ data, jwt, navigate }));
    navigate('/cart')
  };

  const handleOpen = () => {
    // navigate("/register")
    setOpenAuthModal(true);
  };
  const handleClose = () => {
    setOpenAuthModal(false);
  };
  const appDetailsString = localStorage.getItem('appDetails');
  const appDetails = JSON.parse(appDetailsString);

  return (
    <div className="productCard"
      style={{
        width: '19rem',
        height: '30rem',
        // margin: '0.75rem', // This translates to 12px based on Tailwind's default spacing scale
        transition: 'all 0.3s',
        cursor: 'pointer',
        borderRadius: '0.5rem',
        position: 'relative',
        overflow: 'hidden',
        // border: '4px solid transparent',
        // backgroundImage: 'linear-gradient(to right, #2c3e50, #4b79a1)',
        // backgroundClip: 'padding-box' // Ensures background doesn't bleed through the border
      }}
    >
      <AuthModal handleClose={handleClose} open={openAuthModal} />
      <div onClick={handleNavigate}>
        {" "}
        <div className="h-[16rem] p-0 relative cursor-pointer aspect-auto mx-0 shadow-md rounded-lg transition duration-2000 transform hover:scale-105">
          {/* <p className="truncate font-bold" style={{color:'white'}}>{title}</p> */}

          {product?.quantity < 1 && <div className="absolute top-0 right-0 p-2 px-3 bg-red-400 text-white font-medium text-xs">Sold Out</div>}
          <img
            className="h-full w-full object-fill object-center  rounded-t-lg "
            src={imageUrl[0]}
            alt=""
          />
          {/* <Slider images={Array.isArray(imageUrl)?imageUrl:[imageUrl]} /> */}
        </div>
        <div className="textPart bg-white p-2 ">
          <div>
            <p
              className="font-bold opacity-70"
              style={{
                fontSize: '0.925rem',
                height: '2.00rem', // Adjust this value as needed to fix the height
                // overflow: 'hidden',
                // whiteSpace: 'nowrap',
                // textOverflow: 'ellipsis'
              }}
            >
              {truncateText(title, 65)}
            </p>
            {/* <p className="font-semibold opacity-50">{color}</p> */}

            {/* <p
              className="font-bold opacity-70"
              style={{
                fontSize: '0.925rem',
                height: '3.25rem', // Adjust this value as needed to fix the height
                // overflow: 'hidden',
                // whiteSpace: 'nowrap',
                // textOverflow: 'ellipsis'
              }}
            >
              {truncateText(description, 65)}
            </p> */}
          </div>

          <div className="flex space-x-2 items-center">
            <p className="font-semibold text-base">₹{Number.parseFloat(discountedPrice).toFixed(2)}</p>
            <p className="opacity-40 line-through text-sm">₹{price}</p>
            <p className="text-green-600 font-semibold text-xs">
              {Math.floor(discountPersent)}% off
            </p>
          </div>

          <div className="flex flex-row justify-center items-center space-x-2 ">
            {product?.colors?.length > 1 ? (
              <>
                {product.colors.slice(1, 6).map((color, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <img
                      className="h-6 w-6 object-fill object-center rounded-lg"
                      src={color?.imageUrl[0]}
                      alt={color?.color}
                    // onClick={()=>{navigate(`/product/${color?._id}`)}}
                    />
                    <span className="text-xs">{color?.color}</span>
                  </div>
                ))}
                {product.colors.length > 6 && (
                  <div className="flex flex-col items-center">
                    <span className="text-xs">{product.colors.length - 6}+</span>
                  </div>
                )}
              </>
            ) : (
              // Placeholder elements to maintain the layout
              <div className="flex flex-col items-center">
                <div className="h-6 w-6 bg-gray-200 rounded-lg"></div>
                <span className="text-xs text-transparent">Color</span>
              </div>
            )}
          </div>



        </div>
      </div>

      <div style={{
        display: 'flex',
        justifyContent: 'space-between', // Adjusts space between buttons
        gap: '10px', // Optional: Adds space between buttons
      }}>
        <Button
          variant="contained"
          size="small"
          onClick={handleSubmit}
          fullWidth
          disabled={product?.quantity < 1} // Disable button if product quantity is less than 1        
          style={{
            backgroundColor: product?.quantity < 1 ? 'grey' : appDetails?.buttonColor,
            color: 'black',
            borderRadius: 20,
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
            fontWeight: 'bold',
            fontSize: '0.9rem',
            lineHeight: 1.5,
            flex: '1', // Ensures the button grows to take up space
          }}
        >
          {product?.quantity < 1 ? "SOLD OUT" : "ADD TO CART"}
        </Button>

        <Button
          variant="contained"
          size="small"
          onClick={handleBuyNow}
          fullWidth
          style={{
            backgroundColor: product?.quantity < 1 ? 'grey' : appDetails?.buttonColor,
            color: 'black',
            borderRadius: 20,
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
            fontWeight: 'bold',
            fontSize: '0.9rem',
            lineHeight: 1.5,
            flex: '1', // Ensures the button grows to take up space
          }}
        >
          {"BUY NOW"}
        </Button>
      </div>
    </div>
  );
};

export default ProductCard;
