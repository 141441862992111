import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import StorefrontIcon from '@mui/icons-material/Storefront';

const About = () => {
  const appDetailsString = localStorage.getItem('appDetails');
  const appDetails = JSON.parse(appDetailsString);
  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        About Our E-Commerce Platform
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card style={{ display: 'flex', alignItems: 'center', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
            <StorefrontIcon style={{ fontSize: '64px', marginRight: '16px', color: '#3f51b5' }} />
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Our Mission
              </Typography>
              <Typography variant="body1">
              {appDetails?.ourmission}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ display: 'flex', alignItems: 'center', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
            {/* You can add another icon or image here */}
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Our Vision
              </Typography>
              <Typography variant="body1">
              {appDetails?.ourvision}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
