import React, { useState, useEffect } from "react";
import CartItem from "./CartItem";
import { Badge, Button, Typography, TextField, Box, Card, CardContent, Checkbox, FormControlLabel } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../../../Redux/Customers/Cart/Action";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import BackdropComponent from "../BackDrop/Backdrop";
import axios from 'axios';
import api, { API_BASE_URL } from "../../../config/api";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");
  const [toggle, setToggle] = useState(false);
  const [localCart, setLocalCart] = useState([]);
  const [coupen, setcoupen] = useState('');
  const [coupenValue, setcoupenValue] = useState('');
  const [couponStatus, setcouponStatus] = useState(false);
  const [points, setpoints] = useState(0);
  const [updatedPoints, setUpdatedPoints] = useState(0); // Adjusted points based on delivery charges
  const [usePointsForDelivery, setUsePointsForDelivery] = useState(false);

  const { auth, cart } = useSelector((store) => store);
// In your component
const Localcart = useSelector((state) => state.Localcart); // Ensure this matches your state slice

  useEffect(()=>{
setLocalCart(Localcart);
  },[Localcart]);

  useEffect(() => {
    localStorage.removeItem('coupenValue');
    localStorage.removeItem('discountCode');
    setcoupenValue(0);
    const currentCoupon = {
      code: coupen
    }
    axios.post(`${API_BASE_URL}/api/admin/discountCoupens/discountCoupensCheck`, currentCoupon)
      .then((response) => {
        if (response?.data?.status) {
          setcoupenValue(response?.data?.discount);
          localStorage.setItem('coupenValue', JSON.stringify(response?.data?.discount));
          localStorage.setItem('discountCode', JSON.stringify(coupen));
          setcouponStatus(false);
        }
        else {
          setcouponStatus(true);
        }
      })
      .catch(error => console.error('Error fetching coupons:', error));
  }, [coupen]);

  useEffect(() => {
    const data = {
      _id: auth?.user?._id
    }
    axios.post(`${API_BASE_URL}/api/admin/discountCoupens/pointsGet`, data)
      .then((response) => {
        // console.log
        setpoints(response?.data?.points);
        setUpdatedPoints(response?.data?.points);
      })
      .catch(error => console.error('Error fetching points:', error));
  }, [auth]);

  useEffect(() => {
    if (jwt) {
      dispatch(getCart(jwt));
    }
  }, [jwt, toggle, dispatch]);

  const localCartDetailsString = localStorage.getItem("localCartDetails");

  // useEffect(() => {
  //   if (localCartDetailsString != 'undefined') {
  //     const localCartDetails = JSON.parse(localCartDetailsString);
  //     setLocalCart(localCartDetails || []);
  //   }
  // }, [window.localStorage]);

  useEffect(() => {
    const LocaldiscountCode = localStorage.getItem("discountCode");
    const parseddiscountCode = LocaldiscountCode !== 'undefined' ? JSON.parse(LocaldiscountCode) : '';
    setcoupen(parseddiscountCode);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  const appDetailsString = localStorage.getItem('appDetails');
  const appDetails = JSON.parse(appDetailsString);
  
  useEffect(() => {
    // Remove existing values from localStorage
    localStorage.removeItem('delcharge');
    localStorage.removeItem('usedpoints');

    if (usePointsForDelivery && appDetails?.DeliveryCharges) {
        const deliveryCharges = Number(appDetails.DeliveryCharges);
        const newPoints = points - deliveryCharges;
        const pointsAfterDeduction = newPoints >= 0 ? newPoints : 0;
        const remainingDeliveryCharge = deliveryCharges <= points
            ? 0
            : deliveryCharges - points;

        // Set the updated points and localStorage values
        setUpdatedPoints(pointsAfterDeduction);
        localStorage.setItem('delcharge', JSON.stringify(remainingDeliveryCharge));
        localStorage.setItem('usedpoints', JSON.stringify(points - pointsAfterDeduction));
    } else {
        // Reset to original points and delivery charges if not using points for delivery
        setUpdatedPoints(points);
        localStorage.setItem('usedpoints', JSON.stringify(0));
        localStorage.setItem('delcharge', JSON.stringify(appDetails?.DeliveryCharges || 0));
    }
}, [usePointsForDelivery, appDetails, points, updatedPoints]);


  const calculateDiscount = (totalPrice) => {
    const LocalcoupenValue = localStorage.getItem("coupenValue");
    const parsedCoupenValue = LocalcoupenValue !== 'undefined' ? JSON.parse(LocalcoupenValue) : 0;
    const discountPercentage = Number(coupenValue || parsedCoupenValue);
    const discountAmount = (totalPrice * discountPercentage) / 100;
    return `${discountAmount.toFixed(2)}`;
  };



  const renderCartItems = (cartItems, cart) => (
    <div style={{marginTop:'200px'}}>
      {cartItems?.length > 0 ? (
        <div className="lg:grid grid-cols-1 lg:grid-cols-3 lg:px-4 relative gap-4">
          <div className="lg:col-span-2 bg-white rounded-md shadow-md p-4">
            <div className="space-y-3">
              {cartItems.map((item) => (
                <CartItem
                  item={item}
                  showButton={true}
                  setToggle={setToggle}
                  toggle={toggle}
                  key={item?.id}
                />
              ))}
            </div>
          </div>
          <div className="lg:sticky lg:top-0 lg:mt-5 lg:px-4">
            <div className="border p-4 bg-white rounded-md shadow-lg">
              <TextField
                required
                id="coupen"
                name="coupen"
                label="Discount Coupon"
                value={coupen}
                onChange={(e) => { setcoupen(e.target.value) }}
                fullWidth
              />
              {coupen && (
                <Card sx={{ margin: '0px auto', textAlign: 'center', padding: '0px' }}>
                  <CardContent>
                    {couponStatus ? (
                      <Typography variant="h6" color="error">
                        Invalid Discount Coupon
                      </Typography>
                    ) : (
                      <Typography variant="h6" color="green">
                        {`${coupenValue || localStorage.getItem("coupenValue")} % OFF - Enjoy Your Savings!`}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              )
              }


              {/* <Button variant="contained" sx={{ marginTop: '5px' }} onClick={() => { checkCoupen(); }}>
                Apply
              </Button> */}
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  checked={usePointsForDelivery}
                  onChange={(e) => setUsePointsForDelivery(e.target.checked)}
                  name="usePointsForDelivery"
                  color="primary"
                />
              }
              label="Use Points"
              sx={{ marginTop: '10px' }}
            />
            <div className="border p-4 bg-white rounded-md shadow-lg flex justify-between">
              <span className="font-bold text-gray-600">POINTS</span>
              <span className="font-bold text-green-700">
                {updatedPoints}
              </span>
            </div>

            <div className="border p-4 bg-white rounded-md shadow-lg">
              <p className="font-bold text-gray-600 pb-4">PRICE DETAILS</p>
              <hr />
              <div className="space-y-3 font-semibold">
                <div className="flex justify-between pt-3">
                  <span>Price ({cart?.totalItem || cart?.cart?.totalItem} item)</span>
                  <span>₹{Number(cart?.totalPrice || cart?.cart?.totalPrice)?.toFixed(2)}</span>
                </div>
                <div className="flex justify-between">
                  <span>Discount</span>
                  <span className="text-green-700">
                    -₹{Number(cart?.discount || cart?.cart?.discounte || 0)}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Coupon Discount </span>
                  <span className="text-green-700">
                    -₹ {calculateDiscount(Number(cart?.totalDiscountedPrice || cart?.cart?.totalDiscountedPrice)?.toFixed(2))}
                  </span>
                </div>
                {/* <div className="flex justify-between">
                  <span>Delivery Charges</span>
                  <span className="text-green-700">{appDetails?.DeliveryCharges || "Free"}</span>
                </div> */}

                <div className="flex justify-between">
                  <span>Delivery Charges</span>
                  <span className="text-green-700">
                    {appDetails?.DeliveryCharges > 0 && usePointsForDelivery
                      ? appDetails.DeliveryCharges <= points
                        ? "Free"
                        : appDetails.DeliveryCharges - points
                      : appDetails.DeliveryCharges}
                  </span>
                </div>

                <hr />
                <div className="flex justify-between font-bold text-lg">
                  <span>Total Amount</span>
                  <span className="text-green-700">
                    ₹{(
                      Number(appDetails?.DeliveryCharges > 0 && usePointsForDelivery
                        ? appDetails.DeliveryCharges <= points
                          ? 0
                          : appDetails.DeliveryCharges - points
                        : appDetails.DeliveryCharges) +
                      Number(cart?.totalDiscountedPrice || cart?.cart?.totalDiscountedPrice) -
                      calculateDiscount(Number(cart?.totalDiscountedPrice || cart?.cart?.totalDiscountedPrice))
                    ).toFixed(2)}
                  </span>

                </div>
              </div>

              <Button
                onClick={() => navigate("/checkout?step=2")}
                variant="contained"
                sx={{ padding: ".8rem 2rem", marginTop: "2rem", width: "100%" }}
              >
                Check Out
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[70vh] flex flex-col items-center justify-center">
          <ShoppingCartCheckoutIcon sx={{ width: "100px", height: "100px" }} />
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 500, marginTop: "10px", textAlign: "center" }}
          >
            Please Add Items to Cart
          </Typography>
        </div>
      )}
    </div>
  );


  return (
    <>
      <BackdropComponent open={cart?.loading} />
      <div className="">
        {auth?.user ? (
          renderCartItems(cart.cartItems, cart)
        ) : localCart ? (
          renderCartItems(localCart.cartItems, localCart)
        ) : (
          <div className="h-[70vh] flex flex-col items-center justify-center">
            <RemoveShoppingCartIcon sx={{ width: "100px", height: "100px" }} />
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, marginTop: "10px", textAlign: "center" }}
            >
              Please <Link to="/login">Sign In</Link> to your Account First
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

export default Cart;
