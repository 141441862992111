import { ADD_ITEM_TO_LOCAL_CART, UPDATE_LOCAL_CART_TOTALS, SET_LOCAL_CART, REMOVE_ITEM_FROM_LOCAL_CART, UPDATE_LOCAL_CART_ITEM, CLEAR_LOCAL_CART  } from './CartActions';

const initialState = {
  cartItems: [],
  totalPrice: 0,
  totalDiscountedPrice: 0,
  totalItem: 0,
  discount: 0
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_LOCAL_CART:
      return {
        ...state,
        cartItems: action.payload
      };
    case UPDATE_LOCAL_CART_ITEM:
      return {
        ...state,
        cartItems: action.payload
      };
    case UPDATE_LOCAL_CART_TOTALS:
      return {
        ...state,
        totalPrice: action.payload.totalPrice,
        totalDiscountedPrice: action.payload.totalDiscountedPrice,
        totalItem: action.payload.totalItem,
        discount: action.payload.discount
      };
    case SET_LOCAL_CART:
      return {
        ...state,
        ...action.payload
      };
    case REMOVE_ITEM_FROM_LOCAL_CART:
      return {
        ...state,
        cartItems: action.payload
      };
      case CLEAR_LOCAL_CART:
        return {
          ...initialState // Reset state to initial state
        };
    default:
      return state;
  }
};

export default cartReducer;
