import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useSyncExternalStore } from "react";
import OrderCard from "./OrderCard";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import { getOrderHistory } from "../../../Redux/Customers/Order/Action";
import BackdropComponent from "../BackDrop/Backdrop";
import { useState } from "react";
import { cancelOrder } from "../../../Redux/Admin/Orders/Action";
import { OrderStatus } from "../../../utils/utils";
import api, { API_BASE_URL } from "../../../config/api";
import axios from 'axios';

const orderStatus = [
  { label: "All", value: "PLACED", checked: false },
  { label: "Delivered", value: "DELIVERED", checked: false },
  { label: "Cancelled", value: "CANCELLED", checked: false },
  // { label: "Returned", vlue: "returned" },
];

const Order = () => {
  const dispatch = useDispatch();
  const [points, setpoints] = useState([]);
  const [Totalpoints, setTotalpoints] = useState(0);

  const [orderStats, setOrderStats] = useState(orderStatus);
  const [currentOrderStats, setCurrentOrderStats] = useState(
    OrderStatus?.PLACED
  );
  const [toggle, setToggle] = useState(false);
  const jwt = localStorage.getItem("jwt");
  const { order } = useSelector((store) => store);
  const { auth, cart } = useSelector((store) => store);

  useEffect(() => {
    const data = {
      _id: auth?.user?._id
    }
    axios.post(`${API_BASE_URL}/api/admin/discountCoupens/pointstableGet`, data)
      .then((response) => {
        // console.log
        setpoints(response?.data?.points);

        let totalPoints = response?.data?.points.reduce((total, point) => {
          const pointsValue = parseFloat(point.points);
          if (point.d_c === "credit") {
            return total + pointsValue;
          } else if (point.d_c === "debit") {
            return total - pointsValue;
          }
          return total;
        }, 0);
        setTotalpoints(totalPoints);
      })
      .catch(error => console.error('Error fetching points:', error));
  }, [auth]);

  useEffect(() => {
    dispatch(getOrderHistory({ jwt, status: currentOrderStats }));
  }, [jwt, toggle, currentOrderStats]);
  //   const filteredOptions = order?.filter((option) =>
  //   option?.orderStatus?.toLowerCase()?.includes(orderStats.find(data=>data?.checked)?.value?.toLowerCase())
  // );
  const handleFilter = (option) => {
    setOrderStats((prevList) =>
      prevList?.map((stats) =>
        stats?.value === option
          ? { ...stats, checked: true }
          : { ...stats, checked: false }
      )
    );
    setCurrentOrderStats(option);
  };
  const handleCancelOrder = async (orderId) => {
    await dispatch(cancelOrder(orderId));
    await dispatch(getOrderHistory({ jwt, status: currentOrderStats }));
    setToggle(!toggle);
  };
  console.log("users orders ", order.orders);
  return (
    <Box className="px-10" sx={{marginTop:'200px'}}>
      <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
        <Grid item xs={12} md={3}>
          <Box className="h-auto shadow-lg bg-white border p-5 sticky top-20">
            <Typography variant="h6" gutterBottom>Filters</Typography>
            <Box className="space-y-4 mt-10">
              <Typography variant="subtitle2">ORDER STATUS</Typography>
              {orderStats.map((option) => (
                <Box key={option.value} className="flex items-center">
                  <input
                    onClick={() => handleFilter(option.value)}
                    type="checkbox"
                    checked={option.checked}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label className="ml-3 text-sm text-gray-600">
                    {option.label}
                  </label>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box className="space-y-5">
            <TableContainer sx={{ width: '100%' }}>
              <Table sx={{ minWidth: 800 }} aria-label="order table">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Order Id</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Delivery Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.orders?.length > 0 &&
                    order.orders.map((order) => (
                      <OrderCard
                        key={order.order_id}
                        item={order}
                        order={order}
                        Status={order.orderStatus}
                        handleCancelOrder={handleCancelOrder}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
        <Grid item xs={12} md={9}>
          <Box sx={{ mb: 3 }}>
            <TableContainer sx={{ width: '100%' }}>
              <Table sx={{ minWidth: 800 }} aria-label="points table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Order Id</TableCell> */}
                    <TableCell>Order Value</TableCell>
                    <TableCell>Points</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {points?.length > 0 &&
                    points.map((order, index) => (
                      <TableRow key={index}>
                        {/* <TableCell>{order?.order_id}</TableCell> */}
                        <TableCell>{order?.order_value}</TableCell>
                        <TableCell>{order?.points}</TableCell>
                        <TableCell>{new Date(order?.order_date).toLocaleDateString()}</TableCell>
                        <TableCell>{order?.d_c}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>
                        Total Points
                      </TableCell>
                      <TableCell>{Totalpoints}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>


      <BackdropComponent open={order.loading} />
    </Box>
  );
};

export default Order;
