export const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAILURE = 'GET_BANNER_FAILURE';

export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAILURE = 'CREATE_BANNER_FAILURE';

export const REMOVE_BANNER_REQUEST = 'REMOVE_BANNER_REQUEST';
export const REMOVE_BANNER_SUCCESS = 'REMOVE_BANNER_SUCCESS';
export const REMOVE_BANNER_FAILURE = 'REMOVE_BANNER_FAILURE';