import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Menu, MenuItem, Typography, hexToRgb, Box } from "@mui/material";
import { navigation } from "../../../config/navigationMenu";
import AuthModal from "../Auth/AuthModal";
import { useDispatch, useSelector } from "react-redux";
import { deepPurple } from "@mui/material/colors";
import { getLogoDetails, getUser, logout } from "../../../Redux/Auth/Action";
import { getCart } from "../../../Redux/Customers/Cart/Action";

import {
  getCategory,
  getCategoryById,
  getProductCategory,
  getSubCategory,
} from "../../../Redux/Category/Action";
import { Link as ScrollLink, Element } from 'react-scroll';

import {
  Category_level,
  DiscountPercentage,
  MaxPrice,
  MinPrice,
  SearchParmas,
} from "../../../utils/utils";

import WhatsApp from './whatsapp'
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth, cart } = useSelector((store) => store);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const jwt = localStorage.getItem("jwt");
  const location = useLocation();
  const [isFixed, setIsFixed] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [localCart, setLocalCart] = useState([]);

  const localCartDetailsString = localStorage.getItem("localCartDetails");
  const LocalcartRedux = useSelector((state) => state.Localcart); 

  useEffect(() => {
    // if (localCartDetailsString !== 'undefined') {
    //   const localCartDetails = JSON.parse(localCartDetailsString);
    //   setLocalCart(localCartDetails);
    // }
    setLocalCart(LocalcartRedux)
  }, [LocalcartRedux]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024); // Example breakpoint for lg screens
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let data2 = {
      level: Category_level?.TOP,
      pageNumber: 1,
      pageSize: 0,
      searchTerm: ""
    };
    dispatch(getCategory(data2));
  }, []);

  useEffect(() => {
    if (jwt) {
      dispatch(getUser(jwt));
      dispatch(getCart(jwt));
      dispatch(getLogoDetails(jwt))
    }
  }, [jwt]);


  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = (event) => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    navigate("/login")
    setOpenAuthModal(true);
  };
  const handleClose = () => {
    setOpenAuthModal(false);

  };

  const handleCategoryClick = (category, section, item, close) => {
    navigate(`/${category.id}/${section.id}/${item.id}`);
    close();
  };

  useEffect(() => {
    if (auth.user) {
      handleClose();
    }
    if (auth.user?.role !== "ADMIN" && (location.pathname === "/login" || location.pathname === "/register")) {
      navigate(-1)
    }
  }, [auth.user]);

  const handleLogout = () => {
    handleCloseUserMenu();
    dispatch(logout(navigate));
  };
  const handleMyOrderClick = () => {
    handleCloseUserMenu()
    navigate("/account/order")
  }

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const headerHeight = document.querySelector('header').offsetHeight;

      if (offset > headerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fixedStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease-in-out',
    // backgroundImage: 'linear-gradient(135deg, #eddeb4, #e8d5a2)'
    backgroundImage: 'url("/headerbg.png")', // Update with the path to your image
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  };

  const relativeStyle = {
    position: 'relative',
    transition: 'all 0.3s ease-in-out',
    // backgroundImage: 'linear-gradient(135deg, #eddeb4, #e8d5a2)'
  };
  const appDetailsString = localStorage.getItem('appDetails');
  const appDetails = JSON.parse(appDetailsString);

  return (
    <>
    <div className=" pb-0" style={{
      // backgroundImage: 'linear-gradient(135deg, #eddeb4, #e8d5a2)', // Beige gradient example
      backgroundColor: appDetails?.headerColor
    }}>
      {/* Mobile menu */}


      <header
        className=" shadow-md"
        style={isFixed ? { ...fixedStyle } : { ...fixedStyle }}
      >
        <nav aria-label="Top" className="mx-auto" >
          <div className="border-b border-gray-200">
            <div className="flex items-center justify-between h-full px-4 lg:px-8">
              {/* Mobile menu button */}
              <button
                type="button"
                className="rounded-md bg-white p-2 text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="flex-grow flex items-center justify-center" >
                <div>
                  <Link to="/">
                    <img
                      // src={auth?.site?.logo || "https://ecomms3bucket.s3.ap-south-1.amazonaws.com/1703092997879-vrlogo.png"}
                      src={'/logo.png'}
                      alt="Shopwithzosh"
                      style={{
                        height: '5.0rem', // equivalent to 14 in Tailwind's h-14
                        width: '5.0rem',  // equivalent to 14 in Tailwind's w-14
                        borderRadius: '9999px', // equivalent to rounded-full in Tailwind
                        marginLeft: isLargeScreen ? '150px' : '0px',
                      }}
                    />
                  </Link>
                </div>
              </div>

              {/* Navigation links */}
              {/* <div className="hidden lg:flex lg:ml-0 lg:self-stretch z-10">
                <div className="flex h-full space-x-8">
                  {navigation.categories.map((category) => (
                    <Popover key={category.name} className="flex">
                      {({ open, close }) => (
                        <Button
                          onClick={() => navigate(category?.route)}
                          className={classNames(
                            open ? "border-indigo-600 text-indigo-600" : "border-transparent text-gray-700 hover:text-gray-800",
                            "relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out"
                          )}
                        >
                          {category.name}
                        </Button>
                      )}
                    </Popover>
                  ))}
                </div>
              </div> */}

              {/* User controls */}
              <div className="ml-auto flex items-center">
                {/* Cart */}
                <div className="ml-0 sm:ml-50 flow-root lg:ml-0" style={{ marginLeft: '-0px' }}>
                  <Button
                    onClick={() => navigate("/cart")}
                    className="group -m-2 flex items-center p-2"
                  >
                    <ShoppingBagIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                    {auth.user ? (cart.cartItems?.length || 0) : (localCart?.cartItems?.length || 0)}
                    </span>
                    <span className="sr-only">items in cart, view bag</span>
                  </Button>
                </div>

                {auth.user ? (
                  <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    <Avatar
                      className="text-white cursor-pointer"
                      onClick={handleUserClick}
                      sx={{
                        bgcolor: deepPurple[500],
                      }}
                    >
                      {auth.user?.firstName[0].toUpperCase()}
                    </Avatar>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openUserMenu}
                      onClose={handleCloseUserMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={() => {
                        navigate("/profile");
                        handleCloseUserMenu()
                      }}>
                        Profile
                      </MenuItem>
                      <MenuItem onClick={handleMyOrderClick}>
                        My Orders
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <Button
                    onClick={handleOpen}
                    className="text-sm font-medium text-gray-700 hover:text-gray-800"
                  >
                    Sign in
                  </Button>
                )}

              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '5px' }}>
            <Typography sx={{ textAlign: 'center', fontFamily: 'cursive', display: 'inline-block', }}>{appDetails?.tagLine}</Typography>
          </div>
          <Box sx={{
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '3rem', // Adjust height as needed
      // background: "url('/salebg.jpg') no-repeat center center / cover",      
      color: 'black', // Text color
      padding: '0 1rem', // Add some padding
      borderRadius: '8px', // Rounded corners
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
      fontWeight: 'bold', // Bold text
      fontSize: '1.2rem', // Larger text
    }}>
      <Typography
        component="div"
        sx={{
          display: 'inline-block',
          paddingLeft: '100%', // Start from outside the visible area
          animation: 'marquee 15s linear infinite',
          fontSize: '1.5rem', // Adjust font size as needed
          fontWeight: 'bold', // Make the text bold
        }}
      >
        {appDetails?.offermarquee}
      </Typography>
      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(10%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </Box>
        </nav>
      </header>


      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1100] lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="rounded-md bg-white p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="space-y-6 py-6 px-4">
                  {auth.user ? (
                    <div className="flex items-center space-x-4">
                      <Avatar
                        className="text-white"
                        onClick={handleUserClick}
                        aria-controls={openUserMenu ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openUserMenu ? "true" : undefined}
                        sx={{
                          bgcolor: deepPurple[500],
                          color: "white",
                          cursor: "pointer",
                        }}
                      >
                        {auth.user?.firstName[0].toUpperCase()}
                      </Avatar>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openUserMenu}
                        onClose={handleCloseUserMenu}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={() => {
                          navigate("/profile");
                          handleCloseUserMenu()
                        }}>
                          Profile
                        </MenuItem>
                        <MenuItem onClick={handleMyOrderClick}>
                          My Orders
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    <Button
                      onClick={handleOpen}
                    // className="space-y-6 py-6 px-4"              
                    >
                      Signin
                    </Button>
                  )}
                </div>

                <div className="space-y-6 py-6 px-4">
                  {navigation.categories.map((category) => (
                    <div key={category.name} className="flow-root">
                      <Link
                        to={category.route}
                        className="-m-2 block p-2 font-medium text-gray-900"
                        onClick={() => setOpen(false)}
                      >
                        {category.name}
                      </Link>
                    </div>
                  ))}
                </div>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <AuthModal handleClose={handleClose} open={openAuthModal} />
      {/* <Element name="about" className="my-8">
        <h2>About Us</h2>
        <p>Your about section content goes here.</p>
      </Element>
      <Element name="contact" className="my-8">
        <h2>Contact Us</h2>
        <p>Your contact section content goes here.</p>
      </Element> */}
    </div>
    <div style={{ position: 'fixed', top: '500px', right: '5px', zIndex: 1000 }}>
      <WhatsApp/>
    </div>
    </>
  );
}
