import React from 'react'
import "./RefundPolicy.css"
const RefundPolicy = () => {
    const appDetailsString = localStorage.getItem('appDetails');
    const appDetails = JSON.parse(appDetailsString);
  return (
    <div>
        <body>
    <div class="page">
        <div class="header">
            <div class="container">
                <p class="title">Return and Refund Policy for {appDetails?.businessName}</p>
            </div>
        </div>
        <div class="translations-content-container">
            <div class="container">
                <div class="tab-content translations-content-item en visible" id="en">
                    <h2>Return and Refund Policy</h2>
                    <p>Last updated: February 02, 2024</p>
                    <p>Thank you for shopping at {appDetails?.businessName} website and {appDetails?.domian}.com app.</p>
                    <p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our
                        policy on refunds and returns. </p>
                    <p>The following terms are applicable for any products that You purchased with Us.</p>
                    <h2>Interpretation and Definitions</h2>
                    <h3>Interpretation</h3>
                    <p>The words of which the initial letter is capitalized have meanings defined under the following
                        conditions. The following definitions shall have the same meaning regardless of whether they
                        appear in singular or in plural.</p>
                    <h3>Definitions</h3>
                    <p>For the purposes of this Return and Refund Policy:</p>
                    <ul>
                        <li>
                            <p><strong>Application</strong> means the software program provided by the Company
                                downloaded by You on any electronic device, named {appDetails?.domain}.com</p>
                        </li>
                        <li>
                            <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in
                                this Agreement) refers to {appDetails?.businessName}, {appDetails?.footerDetails?.address}.</p>
                        </li>
                        <li>
                            <p><strong>Goods</strong> refer to the items offered for sale on the Service.</p>
                        </li>
                        <li>
                            <p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
                        </li>
                        <li>
                            <p><strong>Service</strong> refers to the Application or the Website or both.</p>
                        </li>
                        <li>
                            <p><strong>Website</strong> refers to {appDetails?.businessName}, accessible from <a
                                    href="http://{appDetails?.domain}.com/" rel="external nofollow noopener"
                                    target="_blank">http://{appDetails?.domain}.com/</a></p>
                        </li>
                        <li>
                            <p><strong>You</strong> means the individual accessing or using the Service, or the company,
                                or other legal entity on behalf of which such individual is accessing or using the
                                Service, as applicable.</p>
                        </li>
                    </ul>
                    <h2>Your Order Cancellation Rights</h2>
                    <p>You are entitled to cancel Your Order within 7 days without giving any reason for doing so.</p>
                    <p>The deadline for cancelling an Order is 7 days from the date on which You received the Goods or
                        on which a third party you have appointed, who is not the carrier, takes possession of the
                        product delivered.</p>
                    <p>In order to exercise Your right of cancellation, You must inform Us of your decision by means of
                        a clear statement. You can inform us of your decision by:</p>
                    <ul>
                        <li>
                            <p>By email: {appDetails?.footerDetails?.email}</p>
                        </li>
                        <li>
                            <p>By phone number: {appDetails?.footerDetails?.phoneNo}</p>
                        </li>
                    </ul>
                    <p>We will reimburse You no later than 14 days from the day on which We receive the returned Goods.
                        We will use the same means of payment as You used for the Order, and You will not incur any fees
                        for such reimbursement.</p>
                    <h2>Conditions for Returns</h2>
                    <ul>
                        <li>Return Accepted Within 7 days after Delivery.</li>
                        <li>Refund Processed Within 7-10 Working days.</li>
                    </ul>

                    <h2>Returning Goods</h2>
                    <p>You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods
                        at the following address:</p>
                    <p>{appDetails?.footerDetails?.address}</p>
                    <p>We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We
                        recommend an insured and trackable mail service. We are unable to issue a refund without actual
                        receipt of the Goods or proof of received return delivery.</p>
                    <h2>Gifts</h2>
                    <p>If the Goods were marked as a gift when purchased and then shipped directly to you, You'll
                        receive a gift credit for the value of your return. Once the returned product is received, a
                        gift certificate will be mailed to You.</p>
                    <p>If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to
                        themselves to give it to You later, We will send the refund to the gift giver.</p>
                    <h3>Contact Us</h3>
                    <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
                    <ul>
                        <li>
                            <p>By email: {appDetails?.footerDetails?.email}</p>
                        </li>
                        <li>
                            <p>By phone number: {appDetails?.footerDetails?.phoneNo}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        
    </div>
    

</body>

    </div>
  )
}

export default RefundPolicy