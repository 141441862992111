export const CREATE_PAYMENT_REQUEST="CREATE_PAYMENT_REQUEST";
export const CREATE_PAYMENT_SUCCESS="CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILURE='CREATE_PAYMENT_FAILURE'

export const UPDATE_PAYMENT_REQUEST = "UPDATE_PAYMENT_REQUEST";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAILURE = "UPDATE_PAYMENT_FAILURE";

export const UPDATE_PAYMENT_LATER_REQUEST = "UPDATE_PAYMENT_LATER_REQUEST";
export const UPDATE_PAYMENT_LATER_SUCCESS = "UPDATE_PAYMENT_LATER_SUCCESS";
export const UPDATE_PAYMENT_LATER_FAILURE = "UPDATE_PAYMENT_LATER_FAILURE";
